import { Informe } from './../../interfaces/interfaces';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit, AfterViewInit {

  data: any[] = [];
  textoBuscar = '';
  zonaComercio = '';
  conexionPerfil = '';
  tipoComercio = '';
  comerciosOn = true;
  viviendasOn = true;
  agrupadaOn = true;
  fueraAgrupadaOn = true;
  activoOn = true;
  inactivoOn = true;
  calidadPorMes: any[] = [];

  zonas = ['Todas las zonas', 'Alde Zaharra', 'Altza', 'Sancho el Sabio', 'San Martín', 'Txomin', 'Cemei', 'Zentek', 'D_BELARTZA', 'Lorea', 'Gros', 'Antiguo', 'Ocio Turistico','Otros'];
  actividades = ['', 'Alimentacion', ];
  conexiones = ['Todas las conexiones','Datadis','Iberdrola','Sinseguimiento'];

  constructor(
    private usuariosServices: UsuariosService
  ) { }

  ngOnInit() {
    this.usuariosServices.cargarPerfiles()
    .subscribe((result: any) => {
      console.log(result);
      this.data = result.data.profiles;
      console.log('CARGAR PERFILES');
    });

    //TEST
    // this.usuariosServices.getInformesPerfil('5e4d5d7278706d02d6c8e737')
    // .subscribe(
    //   (result_informes: any) => {
    //     const informe_descargado: Informe = result_informes.data.informe[0];

    //     const titulo_informe = informe_descargado.titulo+'_'+informe_descargado.versiones[1].version;
    //     this.usuariosServices.downloadLastVersion('634fbf587d23f3ea12187299',titulo_informe);
    //   }
    // )
    // this.getCalidadDatoPorMes();
  }

  toggleComercios(e: any){
    // console.log(e.target.checked);
    this.comerciosOn = e.target.checked;
  }

  toggleViviendas(e: any){
    this.viviendasOn = e.target.checked;
  }

  toggleAgrupada(e: any) {
    this.agrupadaOn = e.target.checked;
  }

  toggleFueraAgrupada(e: any) {
    this.fueraAgrupadaOn = e.target.checked;
  }

  toggleActivo(e: any) {
    this.activoOn = e.target.checked;
  }

  toggleInactivo(e: any) {
    this.inactivoOn = e.target.checked;
  }

  ngAfterViewInit() {
    
  }

}
