import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Comentario, MejoraPropuesta, Usuario } from '../interfaces/interfaces';
import { fondo, logo, moda, alimentacion, otros, is_trending_up, is_trending_down } from './imagenes';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfMakeService2 {

  constructor() {}

  //En este tipo de informe solo utilizamos las mejoras normales, las de potencia no saldŕan

  generatePdf(cuenta: Usuario, potencia: string, tarifa: string, fecha: any){ 
    let mejorasNormales: MejoraPropuesta[] = [];
    let fechaInforme;
    let meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
    
    cuenta.mejoras.map(mejora => {
      if (mejora.tag !== null ) {
        if (mejora.tag === 'potencia') {
        }
      } else {
        mejorasNormales.push(mejora);
      }
    });

    //Calculo de fecha del informe
    let indice: any;
    let anio: any;
    indice = (fecha.substring(5,7)-1); 
    anio = fecha.substring(0,4);
    if (indice == 0){
      anio -=1;
      fechaInforme = meses[11] + ' '+ anio;
    }
    else{
      indice -=1; //Resto -1 porque suelen generar los informes de un mes el mes siguiente
      fechaInforme = meses[indice] + ' '+ anio;
    }
    
    
    
    //Calculo de mejoras y comentarios a imprimir en pdf
    let mejoraNormalReciente: any = '';
    let comentarioReciente: any = '';
      
    //La mejora a imprimir es la ultima que se puso antes de la fecha de creación del seguimiento
    //Deberia ordenar los arrays de mejoras,coment,etc por fecha de menor a mayor (POR HACER)

    mejorasNormales.forEach( (m: any) => {
      if( fecha >= m.date){
        mejoraNormalReciente = m.descripcion;
      }
    });

    cuenta.comentarios.forEach( (k: any) =>{
      if( fecha >= k.date){
        comentarioReciente = k.comentario;
      }
    });
      
    //console.log(mejorasNormales[0].date);
    //console.log(mejorasNormales[1].date);
    //console.log(mejorasNormales[2].date);
    //console.log(mejorasPotencia[0].date);
    //console.log(mejorasPotencia[1].date);
    //console.log(mejorasPotencia[2].date);

    

    let contentRaw: any[] = [
      {
        columns: [
          {
            image: logo,
            width: 270,
            absolutePosition: { x: 15, y: 70 }
          },
          [
            {
              text: cuenta.tituloInforme ? cuenta.tituloInforme.toUpperCase() : cuenta.usuario.toUpperCase(),
              style: 'titulo',
              absolutePosition: { x: 35, y: 150 }
            },
            {
              text: fechaInforme, //fechaInforme
              style: 'header',
              alignment: 'right',
              absolutePosition: { x: 415, y: 160 }
            },
            { canvas: [{ type: 'line', x1: 390, y1: -1, x2: 510, y2: -150, lineWidth: 2 }] },

            {
              text: cuenta.descripcion,
              margin: [0, 0, 0, 0],
              absolutePosition: { x: 30, y: 230 }
            },
            {
              text: [{ text: 'Zona: ', bold: true }, { text: cuenta.zona }],
              absolutePosition: { x: 120, y: 305 }
            },
            {
              text: [{ text: 'Superficie (m2): ', bold: true }, { text: cuenta.superficie }],
              absolutePosition: { x: 280, y: 305 }
            },
            {
              text: [{ text: 'Potencia cont. (kW): ', bold: true }, { text: potencia }],
              absolutePosition: { x: 120, y: 335 }
            },
            {
              text: [{ text: 'Tarifa: ', bold: true }, { text: tarifa }],
              absolutePosition: { x: 280, y: 335 }
            },
            // {
            //   image: cuenta.actividad === 'Otros' ? otros : moda,
            //   fit: [50, 50],
            //   absolutePosition: { x: 415, y: 130 }
            // },
            {
              canvas: [
                {
                  type: 'rect',
                  x: -260,
                  y: 150,
                  w: 550,
                  h: 60,
                  r: 5,
                  lineColor: '#cb222b'
                },
                
                {
                  type: 'rect',
                  x: -260,
                  y: 340,
                  w: 550,
                  h: 120,
                  r: 5,
                  lineColor: '#cb222b'
                },
                {
                  type: 'rect',
                  x: -260,
                  y: 540,
                  w: 550,
                  h: 85,
                  r: 5,
                  lineColor: '#cb222b'
                }
              ]
            }
          ]
        ]
      },

      {
        text: mejorasNormales.length === 0 ? '' : mejoraNormalReciente,
        margin: [0, 0, 0, 25],
        absolutePosition: { x: 30, y: 420 }
      },
      {
        text:
          cuenta.comentarios.length === 0 ? '' : comentarioReciente,
        margin: [0, 0, 0, 25],
        absolutePosition: { x: 30, y: 620 }
      },
  
      {
        text: 'Medidas a implementar',
        style: 'greatHeader',
        absolutePosition: { x: 30, y: 370 }
      },

      {
        text: 'Comentarios',
        style: 'greatHeader',
        absolutePosition: { x: 30, y: 570 }
      },
    ];

    const documentDefinition = {
      background: {
        image: fondo,
        width: 595
      },
      pageMargins: [10, 30, 30, 10],
      content: contentRaw,
      styles: {
        centerme: {
          alignment: 'center'
        },
        titulo: {
          fontSize: 37,
          margin: [0, 10, 0, 10],
          bold: true
        },
        greatHeader: {
          fontSize: 22,
          color: '#cb222b',
          margin: [0, 10, 0, 10],
          bold: true
        },
        header: {
          fontSize: 18,
          margin: [0, 10, 0, 10],
          bold: true
        }
      }
    };
    pdfMake.createPdf(documentDefinition).open();
  }

}
