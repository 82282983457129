import { filter } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Informe } from 'src/app/interfaces/interfaces';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-informe-component',
  templateUrl: './upload-informe-component.component.html',
  styleUrls: ['./upload-informe-component.component.scss']
})
export class UploadInformeComponent implements OnInit {

  @Input() informe: Informe = {
    oid: '',
    oidProfile: '',
    titulo: '',
    fechaInforme: new Date(),
    versiones: []
  }

  public selectedFile!: File;
  public fileName = '';

  constructor(
    public activeModal: NgbActiveModal,
    private datePie: DatePipe
  ) { }

  ngOnInit(): void {
  }

  onFileSelected(event: any) {
    console.log(event);
    this.selectedFile = event.target.files[0];

    if(this.selectedFile) {
      this.fileName = this.selectedFile.name;


    }
  }

  onCancelButton() {
    this.activeModal.close({
      informe_id: '',
      file: ''
    });
  }

  onSaveButton() {
    this.activeModal.close({
      informe_id: this.informe.oid,
      file: this.selectedFile
    });
  }

}
