<div class="row">

    <div class="col-lg-4 col-xlg-3 col-md-5">
        <div class="card" *ngIf="usuario">
            <div class="card-body">
                <div class="m-t-30"> 
                    <img src="../assets/images/users/cabo_rojo.jpg" width="250" />
                    <h2 class="card-title m-t-10">{{ usuario.usuario }}</h2>
                    <i class="icon-people"></i>  <b>  {{ usuario.codigo }}</b>
                    <h6 class="card-subtitle">{{ usuario.actividad }}</h6>

                    <div class="row text-center justify-content-md-center">
                        <div class="col-4">
                            <img width="35px"  [src]="usuario.actividad == 'Comercio: moda, regalos, deportes...' || usuario.actividad == 'Comercio: Moda, regalos, deportes...' ? '../../assets/icon/moda.png' : '../../assets/icon/' + usuario.actividad + '.jpg'">
                        </div>
                        <div class="col-4 superficie-comercio">
                            {{ usuario.superficie }} m<sup>2</sup>
                        </div>
                        <div class="col-4">
                            <img width="65px" [src]="usuario.tipoCuenta | filtroIconos">
                        </div>
                    </div>

                    <div class="row text-center justify-content-md-center">
                        <span *ngIf="!usuario.abierto" class="badge badge-danger">Inactivo</span>
                        <span *ngIf="usuario.esAgrupada" class="badge badge-success">Agrupada</span><br>
                    </div>

                </div>
            </div>
            <div>
                <hr> 
            </div>
            <div class="card-body"> 
                <small class="text-muted">Potencia contratada:</small>
                <h6>{{ potencia ? potencia : '' }} kW</h6>
                <small class="text-muted">Tarifa </small>
                <h6>{{ tarifa ? tarifa : '' }}</h6>
                <small class="text-muted">CUPS:</small>
                <h6>{{ cups ? cups : '' }}</h6>
                <small class="text-muted">Barrio</small>
                <h6>{{ usuario.zona }}</h6>
                <small class="text-muted">Dirección</small>
                <h6>{{ direccion }}</h6>
            </div>
            <div>
                <hr> 
            </div>
            <div class="card-body"> 
                <small class="text-muted">Email </small>
                <h6>{{ usuario.cuentaUsuario }}</h6> 
                
                <small class="text-muted">Teléfono</small>
                <h6>N/D</h6> 
                
                <div class="map-box" *ngIf="urlMaps != ''">
                    {{ usuario.localizacion.coordinates[1] }}
                    <iframe width="260" height="150" id="gmap_canvas" src="https://maps.google.com/maps?q=loc:43.3226847973798+-1.98588314110445&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                </div> 
                <p class="m-t-30">{{ usuario.descripcion }}
                
                <small class="text-muted p-t-30 db">Perfiles sociales</small>
                <br/>
                <button class="btn btn-circle btn-secondary"><i class="fa fa-facebook"></i></button>
                <button class="btn btn-circle btn-secondary"><i class="fa fa-twitter"></i></button>
                <button class="btn btn-circle btn-secondary"><i class="fa fa-youtube"></i></button>
            </div>
        </div>
    </div>

    <div class="col-lg-8 col-xlg-9 col-md-7">
        <div class="card">

            <ul class="nav nav-tabs profile-tab" role="tablist">
                <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#profile" role="tab">Perfil</a> </li>
                <!-- <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#consumos" role="tab">Consumos</a> </li> -->
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#visitas" role="tab">Visitas</a> </li>
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#agrupada" role="tab">Comercializadora</a> </li>
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#historial" role="tab">Historial</a> </li>
                <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#settings" role="tab">Editar perfil</a> </li>
                <li class="nav-item" *ngIf="modoInformes"> <a class="nav-link" data-toggle="tab" href="#informes" role="tab">Generar informes</a></li>
            </ul>

            <div class="tab-content">

                <div class="tab-pane active" id="profile" role="tabpanel">
                    <div class="card-body" *ngIf="usuario">
                        <div class="card-header"> 
                            <h5>Información de perfil</h5>
                        </div>

                        <!-- <h4 class="font-medium m-t-30">Calidad del dato</h4>
                        <h3>Origen: Iberdrola Distribución</h3>
                        <hr>
                        <h5 class="m-t-30"><b>Enero 2021 ({{ calidad2021[0].numDias }} días)</b> ({{ calidad2021[0].totalEstimado }} est. y {{ calidad2021[0].totalCorrecto }} correctos) <span class="pull-right">{{ calidad2021[0].porcentajeCorrecto | number : '1.2-2' }} %</span></h5> -->
                        <!-- <div class="progress">
                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" 
                                style="height:6px;"
                                [style.width]= "calidad2021[0].porcentajeCorrecto.toString().concat('%')"> 
                            </div>
                        </div>
                        <h5>[ Fallos: {{ calidad2021[0].totalFallo }} días - Nulos: {{ calidad2021[0].totalNulo }} días - Ceros: {{ calidad2021[0].totalZero }} días ] </h5>
                        <h5 class="m-t-30"><b>Año 2020</b> ({{ calidad2020[0].numDias }} días - {{ calidad2020[0].totalEstimado }} son estimados) <span class="pull-right">{{ calidad2020[0].porcentajeCorrecto | number : '1.2-2' }} %</span></h5> -->
                        <!-- <div class="progress">
                            <div class="progress-bar bg-info" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" 
                                style="height:6px;"
                                [style.width]= "calidad2020[0].porcentajeCorrecto.toString().concat('%')"> 
                            </div>
                        </div> -->
                        
                        <br><br>
                        <h4 class="font-medium m-t-30">Informes</h4>
                        <hr>
                        <div class="card-body" *ngFor="let informe of informes">
                            Informe {{ informe.fechaInforme }} : {{ informe.titulo }}
                        <a href="#" data-toggle="modal" data-original-title="Editar" (click)="openInformeModal(informe)"><i class="fa fa-pencil text-inverse m-r-10"></i></a>
                        <br>
                            <button style="margin-left: 10px" (click)="downloadVersionInforme(informe)">
                                <i class="fa fa-download"></i> Descargar Última versión
                            </button> 
                            <button style="margin-left: 10px" (click)="uploadVersionInforme(informe)">
                                <i class="fa fa-upload"></i> Subir Nueva versión
                            </button> 
                            
                        </div>

                        <br>
                        <button class="btn btn-success" data-toggle="modal" data-target="#addInformeModal">Añadir Informe</button>
                        <div #informesModal class="modal fade" id="addInformeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="exampleModalLabel1">Añadir Informe</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    </div>
                                    <div class="modal-body">
                                        <form #informesForm="ngForm" (ngSubmit)="addInforme(informesForm)">
                                            <div class="form-group">
                                                <label for="recipient-name" class="control-label">Fecha:</label>
                                                <input type="text" name="date" [(ngModel)]="newInforme.date" class="form-control" id="recipient-name1">
                                            </div>
                                            <div class="form-group">
                                                <label for="message-text" class="control-label">Nombre:</label>
                                                <textarea class="form-control" name="titulo" [(ngModel)]="newInforme.titulo" id="message-text1"></textarea>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="submit" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                                <button type="submit" [disabled]="!informesForm.form.valid" class="btn btn-success">Guardar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
<!-- 
                        <div class="card-body">
                            <h5 class="font-medium m-t-30">Consumos Anuales</h5>
                            <app-kalea-consumos-chart *ngIf="consumo2019!=0" [consumo19]="consumo2019" [consumo20]="consumo2020" [consumo21]="consumo2021"></app-kalea-consumos-chart>
                        </div> -->
                    </div>
                </div>

                <!-- La linea de ion-progress-bar estaba comentada antes de eliminar esta seccion-->
                <!-- <div class="tab-pane" id="consumos" role="tabpanel">
                    <div class="card-body" *ngIf="data">
                        <br>Consumo anual 2020: <b>{{ consumo2020 | number : '1.0-0' }} kWh</b>
                        <br>
                        <ion-progress-bar color="primary" [value]="consumo2020/consumo2019"></ion-progress-bar>
                        Esto supone un {{ consumo2020/consumo2019*100 | number : '1.0-0' }}% del consumo 2019
                        <h4 class="font-medium m-t-30">Consumos</h4>
                        <hr>
                        <app-grafico-consumo titulo="Últimos consumos" label="Fecha" [data]="data"></app-grafico-consumo>
                        <br>
                        <hr>
                        <app-grafico-mensual *ngIf="dataMes" titulo="Consumo mensual" label="Mes" [data]="dataMes"></app-grafico-mensual>
                        <br>
                        <hr>
                        <app-grafico-potencia titulo="Potencia Máxima" label="Mes" [data]="dataPotencia"></app-grafico-potencia>
                        <br>
                    </div>
                </div> -->

                <div class="tab-pane" id="settings" role="tabpanel">
                    <div class="card-body" *ngIf="usuario">
                        <form class="form-horizontal m-t-40" id="updateform" [formGroup]="updateForm" (ngSubmit)="update()">
                
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-md-12">Usuario</label>
                                        <div class="col-md-12">
                                            <input type="text" formControlName="usuario" placeholder="Nombre" class="form-control form-control-line">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="example-email" class="col-md-12">Email</label>
                                        <div class="col-md-12">
                                            <input type="email" formControlName="cuentaUsuario" placeholder="email" class="form-control form-control-line" id="example-email">
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-md-12">Password</label>
                                        <div class="col-md-12">
                                            <input type="password" formControlName="password" placeholder="**********" class="form-control form-control-line">
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                
                            <div class="row">
                                <!-- <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-md-12">Teléfono</label>
                                        <div class="col-md-12">
                                            <input type="text" formControlName="telefono" placeholder="" class="form-control form-control-line">
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="col-sm-12">Zona</label>
                                        <div class="col-sm-12">
                                            <select 
                                                formControlName="zona"
                                                class="form-control form-control-line"
                                                >
                                                <option *ngFor="let zona of zonas" [value]="zona">{{zona}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group">
                                        <label class="col-md-12">Superficie</label>
                                        <div class="col-md-12">
                                            <input type="number" formControlName="superficie" class="form-control form-control-line">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="col-sm-12">Actividad</label>
                                        <div class="col-sm-12">
                                            <select formControlName="actividad" class="form-control form-control-line">
                                                <option value="Comercio: moda, regalos, deportes...">Comercio: moda, regalos, deportes...</option>
                                                <option value="Alimentacion">Alimentacion</option>
                                                <option value="Hosteleria">Hosteleria</option>
                                                <option value="Pelu">Peluquería</option>
                                                <option value="Vivienda">Vivienda</option>
                                                <option value="Empresa">Empresa</option>
                                                <option value="Ocio Turística">Ocio Turística</option>
                                                <option>Otro</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div class="form-group">
                                <label class="col-md-12">Descripción</label>
                                <div class="col-md-12">
                                    <textarea rows="5" formControlName="descripcion" class="form-control form-control-line"></textarea>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn btn-success">Actualizar perfil</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr>
                    <div class="card-body" *ngIf="usuario">
                        <form class="form-horizontal m-t-40" id="updateFormLocalizacion" [formGroup]="updateFormLocalizacion" (ngSubmit)="updateLocalizacion()">
                
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-md-12">Latitud</label>
                                        <div class="col-md-12">
                                            <input type="text" formControlName="latitud" placeholder="latitud" class="form-control form-control-line">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="col-md-12">Longitud</label>
                                        <div class="col-md-12">
                                            <input type="text" formControlName="longitud" placeholder="Longitud" class="form-control form-control-line">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn btn-success">Actualizar Localización</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr>
                    <div class="card-body" *ngIf="usuario">
                        <form class="form-horizontal m-t-40" id="updateFormBooleanos" [formGroup]="updateFormBooleanos" (ngSubmit)="updateBooleanos()">
                
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="custom-control custom-checkbox">
                                            <input type="checkbox" formControlName="activo" class="custom-control-input">
                                            <span class="custom-control-indicator"></span>
                                            <span class="custom-control-description">Activo</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label class="custom-control custom-checkbox">
                                            <input type="checkbox" formControlName="agrupada" class="custom-control-input">
                                            <span class="custom-control-indicator"></span>
                                            <span class="custom-control-description">Agrupada</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="form-group">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn btn-success">Actualizar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="tab-pane" id="visitas" role="tabpanel">
                    <div class="card-body" *ngIf="usuario">
                        <div class="card">

                            <div class="card-header"> 
                                <h5>Visitas</h5>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Empresa</th>
                                            <th>Tipo</th>
                                            <th>Realizada</th>
                                            <th class="text-nowrap">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let visita of seguimientos">
                                        <tr *ngIf="visita.seguimiento.fechaVisita">
                                            <td>{{ visita.seguimiento.fechaVisita }}</td>
                                            <td>{{ visita.seguimiento.empresa}}</td>
                                            <td >
                                                <span *ngIf="visita.seguimiento.esAsesoria" class="badge badge-info">Asesoría</span>
                                                <span *ngIf="!visita.seguimiento.esAsesoria" class="badge badge-warning">Seguimiento</span>
                                            </td>
                                            <td>
                                                <i *ngIf="visita.seguimiento.realizado" class="fa fa-check text-success"></i>
                                                <i *ngIf="!visita.seguimiento.realizado" class="fa fa-close text-danger"></i>
                                            </td>
                                            <td class="text-nowrap">
                                                <a href="#" data-toggle="modal" data-original-title="Editar" (click)="editSeguimiento(visita)"><i class="fa fa-check text-inverse m-r-10"></i></a>
                                                <a href="#" data-toggle="button" data-original-title="Eliminar" (click)="deleteSeguimiento(visita)"> <i class="fa fa-trash text-danger"></i> </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button class="btn btn-success" data-toggle="modal" data-target="#addSeguimientoModal">Añadir Visita</button>
                                <div #seguimientosModal class="modal fade" id="addSeguimientoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="exampleModalLabel1">Añadir visita</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div class="modal-body">
                                                <form #seguimientosForm="ngForm" (ngSubmit)="addSeguimiento(seguimientosForm)">
                                                    <div class="form-group">
                                                        <label for="recipient-name" class="control-label">Fecha:</label>
                                                        <input type="text" name="fechaVisita" [(ngModel)]="newSeguimiento.date" class="form-control" id="recipient-name1">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="message-text" class="control-label">Empresa:</label>
                                                        <textarea class="form-control" name="empresa" [(ngModel)]="newSeguimiento.empresa" id="message-text1"></textarea>
                                                    </div>
                                                    <div class="form-group">

                                                        <label class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="esAsesoria" [(ngModel)]="newSeguimiento.esAsesoria" class="custom-control-input">
                                                            <span class="custom-control-indicator"></span>
                                                            <span class="custom-control-description">¿Asesoría?</span>
                                                        </label>
                                                        
                                                        <label class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="realizado" [(ngModel)]="newSeguimiento.realizado" class="custom-control-input">
                                                            <span class="custom-control-indicator"></span>
                                                            <span class="custom-control-description">Realizado?</span>
                                                        </label>

                                                                     </div> 
                                                    <div class="modal-footer">
                                                        <button type="submit" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                                        <button type="submit" [disabled]="!seguimientosForm.form.valid" class="btn btn-success">Guardar</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="card-header"> 
                                <h5>Comentarios</h5>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Comentario</th>
                                            <th class="text-nowrap">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let comentario of comentarios">
                                        <tr>
                                            <td>{{ comentario.date }}</td>
                                            <td>{{ comentario.comentario }}</td>
                                            <td class="text-nowrap">
                                                <a href="#" data-toggle="modal" data-original-title="Editar" (click)="openComentarioModal(comentario)"><i class="fa fa-pencil text-inverse m-r-10"></i></a>
                                                <a href="#" data-toggle="button" data-original-title="Eliminar" (click)="deleteComentario(comentario)"> <i class="fa fa-close text-danger"></i> </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button class="btn btn-success" data-toggle="modal" data-target="#addComentarioModal">Añadir Comentario</button>
                                <div #comentariosModal class="modal fade" id="addComentarioModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="exampleModalLabel1">Añadir comentario</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div class="modal-body">
                                                <form #comentariosForm="ngForm" (ngSubmit)="addComentario(comentariosForm)">
                                                    <div class="form-group">
                                                        <label for="recipient-name" class="control-label">Fecha:</label>
                                                        <input type="text" name="date" [(ngModel)]="newComentario.date" class="form-control" id="recipient-name1">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="message-text" class="control-label">Comentario:</label>
                                                        <textarea class="form-control" maxlength="340" name="comentario" [(ngModel)]="newComentario.comentario" id="message-text1"></textarea>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="submit" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                                        <button type="submit" [disabled]="!comentariosForm.form.valid" class="btn btn-success">Guardar</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                        </div>
                    </div>
                </div>

                <div class="tab-pane" id="agrupada" role="tabpanel">
                    <div class="card-body" *ngIf="usuario">
                        <div class="card">
                            <div *ngIf="fechaIniContrato">
                                <div class="card-header"> 
                                    <h5>Datadis</h5>
                                </div>
                                <br>
                                <div style="padding-left: 15px;">
                                    <h5>Fecha de registro: {{ fechaIniContrato }} </h5>
                                    <h5>Fecha de finalización: {{ fechaFinContrato }} </h5>
                                </div>
                                
                                <br>
                            </div>
                            <div class="card-header"> 
                                <h5>Comercializadoras</h5>
                            </div>

                            <!--<h4 class="font-medium m-t-30">Agrupada</h4>-->
                            <!-- <hr> -->
                            <!--<h5 class="m-t-30" *ngIf="usuario.esAgrupada">Este establecimiento ya forma parte de la agrupación de consumidores eléctricos impulsada por el programa SmartKalea, con la nueva rebaja de precios se conseguirá un ahorro anual de <b>{{ ahorro_string }}€</b>.</h5>
                            <h5 class="m-t-30" *ngIf="!usuario.esAgrupada">Este establecimiento aún no está en la agrupada.</h5>-->
                            <h4 class ="font-medium m-t-30">Histórico de comercializadoras</h4>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Desde</th>
                                            <th>Hasta</th>
                                            <th>Comercializadora</th>
                                            <th>Agrupada</th>
                                            <th class="text-nowrap">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let comercializadora of tablaComercializadora">
                                        <tr>
                                            <td>{{ comercializadora.desde }}</td>
                                            <td>{{ comercializadora.hasta ? comercializadora.hasta : 'Actual'}}</td>
                                            <td>{{ comercializadora.comercializadora }}</td>
                                            <td>
                                                <i *ngIf="comercializadora.esAgrupada" class="fa fa-check text-success"></i>
                                                <i *ngIf="!comercializadora.esAgrupada" class="fa fa-close text-danger"></i>
                                            </td>
                                            <td class="text-nowrap">
                                                <a href="#" data-toggle="modal" data-original-title="Editar" (click)="editComercializadora(comercializadora)"><i class="fa fa-pencil text-inverse m-r-10"></i></a>
                                                <a href="#" data-toggle="button" data-original-title="Eliminar" (click)="deleteComercializadora(comercializadora)"> <i class="fa fa-trash text-danger"></i> </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button class="btn btn-success" data-toggle="modal" (click)="addComercializadora()">Añadir Comercializadora</button>
                            </div>
                            
                        </div>
                        
                    </div>

                </div>

                <div class="tab-pane" id="historial" role="tabpanel">
                    <div class="card-body" *ngIf="usuario">
                        <div class="card">

                            <div class="card-header"> 
                                <h5>Inversiones</h5>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Inversión</th>
                                            <th class="text-nowrap">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let inversion of inversiones">
                                        <tr>
                                            <td>{{ inversion.date }}</td>
                                            <td>{{ inversion.inversion }}</td>
                                            <td class="text-nowrap">
                                                <a href="#" data-toggle="modal" data-original-title="Editar" (click)="openInversionModal(inversion)"><i class="fa fa-pencil text-inverse m-r-10"></i></a>
                                                <a href="#" data-toggle="button" data-original-title="Eliminar" (click)="deleteInversion(inversion)"> <i class="fa fa-trash text-danger"></i> </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button class="btn btn-success" data-toggle="modal" data-target="#addInversionModal">Añadir Inversión</button>
                                <div #inversionModal class="modal fade" id="addInversionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="exampleModalLabel1">Añadir Inversión</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div class="modal-body">
                                                <form #inversionForm="ngForm" (ngSubmit)="addInversion(inversionForm)">
                                                    <div class="form-group">
                                                        <label for="recipient-name" class="control-label">Fecha:</label>
                                                        <input type="text" name="date" [(ngModel)]="newInversion.date" class="form-control" id="recipient-name1">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="message-text" class="control-label">Inversión:</label>
                                                        <textarea class="form-control" maxlength="340" name="inversion" [(ngModel)]="newInversion.inversion" id="message-text1"></textarea>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="submit" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                                        <button type="submit" [disabled]="!inversionForm.form.valid" class="btn btn-success">Guardar</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="card-header"> 
                                <h5>Mejoras Propuestas</h5>
                            </div>
                            <div class="profiletimeline">
                                <div class="sl-item" *ngFor="let mejora of mejoras">
                                    <div class="sl-left"> <img src="../assets/images/users/letterT.png" alt="user" class="img-circle" /> </div>
                                    <div class="sl-right">
                                        <div><b>{{ mejora.titulo }}</b> <span class="sl-date"> Fecha: {{ mejora.date }}</span>
                                            <p>
                                                <a href="#" data-toggle="modal" data-original-title="Editar" (click)="openMejoraModal(mejora)"><i class="fa fa-pencil text-inverse m-r-10"></i></a>
                                                <a href="#" data-toggle="button" data-original-title="Eliminar" (click)="deleteMejora(mejora)"> <i class="fa fa-close text-danger"></i> </a>
                                            </p>
                                            <p>{{ mejora.descripcion }}</p>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div #mejorasModal class="modal fade" id="addMejoraModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="exampleModalLabel1">Añadir mejora</h4>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div class="modal-body">
                                                <form #mejorasForm="ngForm" (ngSubmit)="addMejora(mejorasForm)">
                                                    <div class="form-group">
                                                        <label for="recipient-name" class="control-label">Fecha:</label>
                                                        <input type="text" name="date" [(ngModel)]="newMejora.date" class="form-control" id="recipient-name1">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="recipient-name" class="control-label">Tag:</label>
                                                        <input type="text" name="tag" [(ngModel)]="newMejora.tag" class="form-control" id="recipient-name2">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="recipient-name" class="control-label">Título:</label>
                                                        <input type="text" name="titulo" [(ngModel)]="newMejora.titulo" class="form-control" id="recipient-name3">
                                                    </div>
                                                    <div class="form-group">
                                                        <label for="message-text" class="control-label">Descripción:</label>
                                                        <textarea class="form-control" maxlength="430" name="descripcion" [(ngModel)]="newMejora.descripcion" id="message-text1"></textarea>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="submit" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                                        <button type="submit" [disabled]="!mejorasForm.form.valid" class="btn btn-success">Guardar</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                                <!-- <div class="sl-item">
                                    <div class="sl-left"> <img src="../assets/images/users/2.jpg" alt="user" class="img-circle" /> </div>
                                    <div class="sl-right">
                                        <div> <a href="#" class="link">{{ usuario.usuario }}</a> <span class="sl-date">5 minutes ago</span>
                                            <div class="m-t-20 row">
                                                <div class="col-md-3 col-xs-12"><img src="../assets/images/big/imgInforme.png" alt="user" class="img-responsive radius" /></div>
                                                <div class="col-md-9 col-xs-12">
                                                    <p>disponible nuevo informe <a href="#"> Informe de seguimiento</a></p><a href="#" class="btn btn-primary"> Descargar</a></div>
                                            </div>
                                            <div class="like-comm m-t-20"> <a href="javascript:void(0)" class="link m-r-10">2 comentarios</a> <a href="javascript:void(0)" class="link m-r-10"><i class="fa fa-heart text-danger"></i> 1 OK</a> </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="sl-item">
                                    <div class="sl-left"> <img src="../assets/images/users/4.jpg" alt="user" class="img-circle" /> </div>
                                    <div class="sl-right">
                                        <div><a href="#" class="link">{{ usuario.usuario }}</a> <span class="sl-date">5 minutes ago</span>
                                            <blockquote class="m-t-10">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                                            </blockquote>
                                        </div>
                                    </div>
                                </div> -->
                                <br><br>
                                <button class="btn btn-success" data-toggle="modal" data-target="#addMejoraModal">Añadir Mejora</button>
                            </div>



                            
                        </div>
                        
                    </div>

                </div>
                
                <div *ngIf="modoInformes" class="tab-pane" id="informes" role="tabpanel">
                    <div class="card-body" *ngIf="usuario">
                        <div class="card">
                           <div class="card-header">
                            <h5>Informes de seguimiento</h5>
                           </div>
                           <div class="card-body">
                            <div #informesSeguimientoModal class="modal fade" id="buildInformeSeguimientoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1">
                                <div class="modal-dialog" role="document">

                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="exampleModalLabel1">Generar informe de seguimiento</h4>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                        <div class="model-body">
                                                <form #informeSeguimientoForm="ngForm" (ngSubmit)="generarInformeSeguimientoV2()">
                                                    <div class="row" style="margin-left: 10px; margin-top: 5px;">
                                                        <div class="form-group">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overrideNombre" [(ngModel)]="newDataInforme.overrideNombre" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">Cambiar nombre de comercio en informe</span>
                                                            </label>
                                                                <input *ngIf="newDataInforme.overrideNombre" type="text" name="nombre" [(ngModel)]="newDataInforme.nombre" class="form-control form-control-line">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overrideValorUno" [(ngModel)]="newDataInforme.overrideValueUno" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">Cambiar valor de primer periodo</span>
                                                            </label>
                                                                <input *ngIf="newDataInforme.overrideValueUno" type="number" name="valueUno" [(ngModel)]="newDataInforme.valueUno" class="form-control form-control-line">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overrideValueDos" [(ngModel)]="newDataInforme.overrideValueDos" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">Cambiar valor de segundo periodo</span>
                                                            </label>
                                                            <input *ngIf="newDataInforme.overrideValueDos" type="number" name="valueDos" [(ngModel)]="newDataInforme.valueDos" class="form-control form-control-line">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overridePotencia" [(ngModel)]="newDataInforme.overridePotencia" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">Comentario de evolución y potencia</span>
                                                            </label>
                                                            <input *ngIf="newDataInforme.overridePotencia" type="text" name="evolucion_potencia" [(ngModel)]="newDataInforme.evolucion_potencia" class="form-control form-control-line">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overrideMejorasPrevias" [(ngModel)]="newDataInforme.overrideMejorasPrevias" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">Comentario de mejoras previas</span>
                                                            </label>
                                                            <input *ngIf="newDataInforme.overrideMejorasPrevias" type="text" name="mejoras_previas" [(ngModel)]="newDataInforme.mejoras_previas" class="form-control form-control-line">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overrideNuevasRecomendaciones" [(ngModel)]="newDataInforme.overrideNuevasRecomendaciones" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">Comentario de nuevas recomendaciones</span>
                                                            </label>
                                                            <input *ngIf="newDataInforme.overrideNuevasRecomendaciones" type="text" name="nuevas_recomendaciones" [(ngModel)]="newDataInforme.nuevas_recomendaciones" class="form-control form-control-line">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overrideComentarioAdicional" [(ngModel)]="newDataInforme.overrideComentarioAdicional" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">Comentarios adicionales</span>
                                                            </label>
                                                            <input *ngIf="newDataInforme.overrideComentarioAdicional" type="text" name="comentario_adicional" [(ngModel)]="newDataInforme.comentario_adicional" class="form-control form-control-line">

                                                            <label class="custom-control custom-checkbox">
                                                                <input type="checkbox" name="overrideConsumos" [(ngModel)]="newDataInforme.overrideConsumos" class="custom-control-input">
                                                                <span class="custom-control-indicator"></span>
                                                                <span class="custom-control-description">El informe no tiene consumos</span>
                                                            </label>
                                                            


                                                        </div> 
                                                        </div>
                                                    <div class="row">
                                                        
                                                    </div>
                                                </form>    
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-default" (click)="generarMockupSeguimiento()">Mockup</button>
                                            <button type="button" [disabled]="!informeSeguimientoForm.form.valid" (click)="generarInformeSeguimientoV2()" class="btn btn-default">Generar informe</button>
                                            <button type="submit" class="btn btn-default" data-dismiss="modal">Cerrar</button>
                                            <!-- <button type="submit" [disabled]="!mejorasForm.form.valid" class="btn btn-success">Guardar</button> -->
                                        </div>    
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <button class="btn btn-success" data-toggle="modal" data-target="#buildInformeSeguimientoModal">Generar informe de seguimiento</button> 
                                </div>
        
                           </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>