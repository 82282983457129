<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">

            <ul id="sidebarnav">
                <li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img src="../assets/images/users/1.jpg" alt="user" /><span class="hide-menu">Fomento San Sebastián </span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a href="javascript:void()">Mi Perfil </a></li>
                        <li><a (click)="logout()">Salir</a></li>
                    </ul>
                </li>
                <!-- <li class="nav-devider"></li>
                <li class="nav-small-cap">PERSONAL</li> -->
                <li> 
                    <!-- <a class="has-arrow waves-effect waves-dark" href="index.html" aria-expanded="false">
                        <i class="mdi mdi-gauge"></i>
                        <span class="hide-menu">Accesos <span class="label label-rouded label-themecolor pull-right">4</span>
                    </span></a> -->
                    <ul>
                        <li><a href="index.html">Listado</a></li>
                        <!-- <li><a href="/graficas1">Calidad del dato</a></li> -->
                        <li><a href="/notificaciones">Notificaciones</a></li>
                        <li><a href="/agrupada">Agrupada</a></li>
                        <li><a href="/seguimientos">Visitas</a></li>
                        <li><a href="/agrupadas">Agrupadas</a></li>
                        <!-- <li><a href="/progress">Dashboard</a></li> -->
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->