<div class="flex">
    <div class="flex-item">
      <div class="chart">
        <canvas baseChart
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [chartType]="pieChartType"
          [options]="pieChartOptions"
          [colors]="pieChartColors"
          [legend]="pieChartLegend">
        </canvas>
      </div>
      <!-- <div style="display: block;">
        <canvas
          class="canvas_for_chart"
          baseChart
          width="400"
          height="400"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [colors]="barChartColors"
          [legend]="barChartLegend"
          [chartType]="barChartType"
        ></canvas>
      </div> -->
    </div>
</div>