

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Suministros eléctricos</h4>
        <h6 class="card-subtitle">Listado de suministros eléctricos de la base de datos. Puedes filtrar en las búsquedas y desplegables.</h6>
        <div class="table-responsive m-t-40">

          <div class="row justify-content-center">
            <div>
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="comerciosOn" (change)="toggleComercios($event)" id="filledInCheckbox1">
              <label for="filledInCheckbox1">Comercios</label>
              &nbsp;
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="viviendasOn" (change)="toggleViviendas($event)" id="filledInCheckbox2">
              <label for="filledInCheckbox2">Viviendas</label>
              &nbsp;
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="agrupadaOn" (change)="toggleAgrupada($event)" id="filledInCheckbox3">
              <label for="filledInCheckbox3">Agrupada</label>
              &nbsp;
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="fueraAgrupadaOn" (change)="toggleFueraAgrupada($event)" id="filledInCheckbox4">
              <label for="filledInCheckbox4">Fuera de agrupada</label>
              &nbsp;
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="activoOn" (change)="toggleActivo($event)" id="filledInCheckbox5">
              <label for="filledInCheckbox5">Activo</label>
              &nbsp;
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="inactivoOn" (change)="toggleInactivo($event)" id="filledInCheckbox6">
              <label for="filledInCheckbox6">Inactivo</label>

            </div>
            &nbsp;
          </div>

          <div class="row justify-content-center">
            <div>
              <h6><input [(ngModel)]="textoBuscar" placeholder="Buscar usuario..."></h6>
            </div>
            &nbsp;
            <div>
              <h6>
                <select
                  name="zonaComercio"
                  [(ngModel)]="zonaComercio">
                  <option disabled selected>Selecciona una zona</option>
                  <option
                    *ngFor="let zona of zonas"
                    [value]="zona">{{zona}}</option>
                </select>
              </h6>
            </div>
            &nbsp;
            <div>
              <h6>
                <select
                  name="conexionPerfil"
                  [(ngModel)]="conexionPerfil">
                  <option disabled selected>Selecciona una conexión</option>
                  <option
                    *ngFor="let conexion of conexiones"
                    [value]="conexion">{{conexion}}</option>
                </select>
              </h6>
            </div>
            <!-- &nbsp;
            <div>
              <h6>
                <select
                  name="zonaActividad"
                  [(ngModel)]="tipoComercio">
                  <option disabled selected>Selecciona una actividad</option>
                  <option
                    *ngFor="let actividad of actividades"
                    [value]="actividad">{{actividad}}</option>
                </select>
              </h6>
            </div> -->
          </div>

          <!-- <div class="clear-selection" title="Click to Clear Selections" (click)="clearSelection()" *ngIf="selected_count">Clear Selection</div> -->
                                    
        <table id="example23" class="display nowrap table table-hover" cellspacing="0" width="100%">                 
            <thead>                          
              <tr>
                <th>ID</th>
                <th>Código</th>
                <th>Nombre</th>
                <th>Etiquetas</th>
                <th>Superficie</th>
                <th>Barrio</th>
                <th>Conexión</th>
                <th>Actividad</th>
              </tr>
            </thead>
            <tbody *ngFor="let perfil of data | filtro:textoBuscar | filtro:zonaComercio:false:'zona' | filtro:conexionPerfil:false:'tipoCuenta' | filtroActividad:viviendasOn:comerciosOn | filtroAgrupada:agrupadaOn:fueraAgrupadaOn | filtroInactivo:activoOn:inactivoOn ">
              <tr>
                <td>{{ perfil.idPerfil }}</td>
                <td>{{ perfil.codigo }}</td>
                <td>
                  <a routerLink="{{'perfil/' + perfil.usuario}}">
                    <b>{{ perfil.usuario }}</b>
                  </a>
                </td>
                <td>
                  <span *ngIf="perfil.esAgrupada" class="badge badge-success">Agrupada</span><br>
                  <span *ngIf="!perfil.abierto" class="badge badge-danger">Inactivo</span>
                </td>
                <td>{{ perfil.superficie }} m<sup>2</sup></td>
                <td><span class="label label-info">{{ perfil.zona }}</span> </td>
                <!-- <td>
                  <img width="50px" [src]="perfil.tipoCuenta ==='Iberdrola' ? '../../assets/icon/iberdrola.png' : '../../assets/icon/datadis.png'">
                </td> -->
                <td>
                  <img width="50px" [src]="perfil.tipoCuenta | filtroIconos">

                  <!-- <img *ngIf="perfil.tipoCuenta == 'Datadis'" src="../../assets/icon/datadis.png" width="50px">
                  <img *ngIf="perfil.tipoCuenta == 'Iberdrola'" src="../../assets/icon/iberdrola.png" width="50px">
                  <img *ngIf="perfil.tipoCuenta == 'Sinseguimiento'" src="../../assets/icon/sinseguimiento.png" width="50px"> -->
                </td>
                <td>
                  <img width="35px"  [src]="perfil.actividad == 'Comercio: moda, regalos, deportes...' || perfil.actividad == 'Comercio: Moda, regalos, deportes...' ? '../../assets/icon/moda.png' : '../../assets/icon/' + perfil.actividad + '.jpg'">
                </td>
              </tr>      
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> 
</div>


               