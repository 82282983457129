import gql from 'graphql-tag';

export const Users = gql`
query {profiles {
  identifier
  codigo
  usuario
  cuentaUsuario
  tipoCuenta
  tituloInforme
  zona
  actividad
  superficie
  comentarios {
    date
    comentario
  }
  idUsuario
  idPerfil
  nombrePerfil
  descripcion
  esAgrupada
  abierto
  localizacion  {
    coordinates
  }
  comercializadoras {
    date
    comercializadora
    esAgrupada
  }
  mejoras {
    date
    titulo
    descripcion
    tag
  }
}}
`;

export const Profile = gql`
query Profile($usuario: String) {
  profiles(usuario: $usuario) {
    identifier
    codigo
    usuario
    cuentaUsuario
    tipoCuenta
    tituloInforme
    esAgrupada
    abierto
    zona
    actividad
    superficie
    comentarios {
      date
      comentario
    }
    inversiones {
      date
      inversion
    }
    comercializadoras {
      date
      comercializadora
      esAgrupada
    }
    idUsuario
    idPerfil
    nombrePerfil
    descripcion
    localizacion  {
      coordinates
    }
    mejoras {
      date
      titulo
      descripcion
      tag
    }
  }
}
`;

export const CalidadDato = gql`
query PruebaEstimaciones($oidProfile: String, $initDate: Date, $endDate: Date) {
  profiles (usuario: $oidProfile) {
    identifier
    codigo
    usuario
    cuentaUsuario
  }
  analisis (oidProfile: $oidProfile,initDate: $initDate, endDate: $endDate) {
    numDias
    totalCorrecto
    totalEstimado
    totalFallo
    totalNulo
    totalZero
    porcentajeEstimado
    porcentajeCorrecto
    porcentajeZero
  }
  estimacion (oidProfile: $oidProfile) {
    oidProfile
    estado
    timestamp
  }
}
`;

export const CalidadDatoZonas = gql`
  query PruebaEstimacionesZona($zona: String!,$initDate: Date!,$endDate: Date!,$alcance: String) {
    analisisZona(codigoZona: $zona,initDate: $initDate,endDate: $endDate,alcance: $alcance) {
      codigoZona
      alcance
      totales {
        totalElementos
        totalElementosCorrectos
        totalElementosEstimados
      },
      porcentajes {
        porcentajeCorrectos
        porcentajeEstimados
      },
      individuales {
        nombrePerfil
        oidProfile
        numDias
        totalEstimado
        totalZero
        totalCorrecto
        totalFallo
        totalNulo
        porcentajeEstimado
        porcentajeZero
        porcentajeCorrecto
        porcentajeFallo
        porcentajeNulo
      }
    }
  }
`;

export const CalidadDatoTotal = gql`
query estimacion($initDate: Date,$endDate: Date) {
  analisisEst: analisisCompleto(initDate:$initDate,endDate: $endDate) {
    oidProfile
    nombrePerfil
    initDate
    endDate
    numDias
    totalCorrecto
    totalEstimado
    totalZero
    totalNulo
    totalFallo
    porcentajeCorrecto
    porcentajeEstimado
    porcentajeZero
    porcentajeNulo
    porcentajeFallo
  }
}
`;

export const MutatePerfil = gql`
  mutation SetDescripcionCuenta (\
    $identifier: String,\
    $descripcion: String,\
    $actividad: String,\
    $superficie: Float,\
    $zona: String\
) {\
updateProfile (\
    profileData: {\
      identifier: $identifier,\
      descripcion: $descripcion,\
      actividad: $actividad,\
      superficie: $superficie,\
      zona: $zona\
    }) {\
    profile {\
      identifier\
      descripcion\
      actividad\
      superficie\
      zona\
    }\
  }\
}
`;

export const MutateCreateInforme = gql`
  mutation createInforme (\
      $profile:String!,\
      $titulo:String!,\
      $fecha:Date!\
  ) {\
  createInforme (\
      informe: {\
        oidProfile: $profile,\
        titulo: $titulo,\
        fechaInforme: $fecha\
      }) {\
      informe {\
        oid,\
        oidProfile,\
        fechaInforme,\
        titulo,\
        createdAt,\
        versiones {\
          internalLink,\
          version\
        }\
      }\
    }\
  }
`;

export const MutateUpdateInforme = gql`
  mutation updateInforme (\
      $oid:String!,\
      $informe:InformeUpdate!\
    ) {\
      updateInforme (\
        id: $oid,\
        informe:$informe\
      ) {\
        informe {\
          oid,\
          oidProfile,\
          fechaInforme,\
          titulo,\
          createdAt,\
          versiones {\
            internalLink,\
            version\
          }\
      }\
    }\
  }
`;

export const InformesPerfil = gql`
  query informesPerfil (\
      $profile: String!\
    ) {\
      informe (\
        oidProfile: $profile\  
      ) {\
        oid,\
        oidProfile,\
        titulo,\
        fechaInforme,\
        createdAt,\
        versiones {\
          internalLink,\
          version\
        }\
      }\
    }`;


export const MutateCambiarBooleanos = gql`
    mutation cambioProfileAgrupada (\
      $valorAgrupada: Boolean,\
      $valorActivo: Boolean,\
      $identificador: String\
      ) {\
      updateProfile (\
        profileData: {\
          identifier: $identificador,\
          esAgrupada: $valorAgrupada,\
          abierto: $valorActivo,\
        }) {\
        profile {\
          esAgrupada\
          abierto\
          identifier\
        }\
      }\
    }
`;

export const MutateLocalizacion = gql`
  mutation cambioLocalizacion (\
      $valorLatitud: Float,\
      $valorLongitud: Float,\
      $identificador: String\
      ) {\
        updateProfileLocalizacion (\
          profileId: $identificador,\
          localizacion: {\
            latitud: $valorLatitud,\
            longitud: $valorLongitud,\
        }) {\
        profile {\
          codigo\
        }\
      }\
    }
`;

export const MutateAddComentario = gql`
  mutation AddComentario (\
      $profileId: String,\
      $date: Date,\
      $comentario: String\
    ) {\
    addComentario(\
      profileId: $profileId,\
      comentario: {\
        date: $date,\
        comentario: $comentario\
      }\
    ) {\
    comentarios {\
      date\
      comentario\
    }\
  }\
}
`;

export const MutateEditComentario = gql`
mutation ReplaceComentario (\
  $profileId: String,\
  $oldDate: Date,\
  $oldComentario: String,\
  $newDate: Date,\
  $newComentario: String\
  ) {\
  replaceComentario (\
    profileId: $profileId,\
    comentarioOld: {\
      date: $oldDate,\
      comentario: $oldComentario\
    },\
    comentarioNew: {\
      date: $newDate,\
      comentario: $newComentario\
    }\
    ) {\
    comentarios {\
      date comentario\
    }\
  }\
}
`;

export const MutateDeleteComentario = gql`
mutation DeleteComentario (
  $profileId: String,\
  $date: Date,\
  $comentario: String,\
  ) {
  deleteComentario(
    profileId: $profileId,
    comentario: {
      date: $date,
      comentario: $comentario
    }
  ) {
    comentarios {
      date
      comentario
    }
  }
}
`;

export const MutateAddMejora = gql`
  mutation AddMejoraPropuestaPotencia (\
    $profileId: String,\
    $date: Date,\
    $titulo: String,\
    $descripcion: String\
    $tag: String\
  ) {\
  addMejoraPropuesta (\
    profileId: $profileId,\
    mejora: {\
      date: $date,\
      titulo: $titulo,\
      descripcion: $descripcion\
      tag: $tag\
    } )\
    {\
      mejoras {\
        date titulo descripcion tag\
      }\
    }\
  }
`;

export const MutateEditMejora = gql`
mutation ReplaceMejoraPotencia (
  $profileId: String,
  $oldDate: Date,
  $oldTitulo: String,
  $oldDescripcion: String,
  $newDate: Date,
  $newTitulo: String,
  $newDescripcion: String
  $tag: String
  ) {
  replaceMejoraPropuesta (
    profileId: $profileId,
    mejoraOld: {
      date: $oldDate,
      titulo: $oldTitulo,
      descripcion: $oldDescripcion
      tag: $tag
    }, 
    mejoraNew: {
      date: $newDate,
      titulo: $newTitulo,
      descripcion: $newDescripcion
      tag: $tag
    } )
    {
      mejoras {
        date titulo descripcion tag
      }
    }
  }
`;

export const MutateDeleteMejora = gql`
mutation DeleteMejoraPropuesta (
  $profileId: String,\
  $date: Date,\
  $titulo: String,\
  $descripcion: String,\
  ) {
  deleteMejoraPropuesta(
    profileId: $profileId,
    mejora: {
      date: $date,
      titulo: $titulo,
      descripcion: $descripcion
    }
  ) {
    mejoras {
      date
      titulo
      descripcion
    }
  }
}
`;

export const Cups = gql`
query Suministro($oidProfile: String) {
  suministro(oidProfile: $oidProfile) {
    cups
    direccion
  }
}
`;

export const Contrato = gql`
query Contrato($oidCuenta: String) {
  contrato(oidCuenta: $oidCuenta) {
    tarifa
    potenciaP1
  }
}
`;

export const Consumo = gql`
query Consumo(
  $idPerfilIn: [Int],
  $firstYearInitDate:Date,
  $firstYearEndDate:Date,
  $secondYearInitDate:Date,
  $secondYearEndDate:Date,
  $extraYearInitDate: Date,
  $extraYearEndDate: Date
){
  consumo2019: sumConsumoAnual(idPerfilIn: $idPerfilIn, initDate: $firstYearInitDate,endDate: $firstYearEndDate) {
    idPerfil
    year
    initDate
    endDate
    consumoWh
  }
  consumo2020: sumConsumoAnual(idPerfilIn: $idPerfilIn, initDate: $secondYearInitDate,endDate: $secondYearEndDate) {
    idPerfil
    year
    initDate
    endDate
    consumoWh
  }
  consumo2021: sumConsumoAnual(idPerfilIn: $idPerfilIn, initDate: $extraYearInitDate, endDate: $extraYearEndDate) {
    idPerfil
    year
    initDate
    endDate
    consumoWh
  }
}
`;

export const Informes = gql`
  query Informe($oidProfile: String)  {
    informe(oidProfile: $oidProfile ) {
      informe {
        oid,
        oidProfile,
        fechaInforme,
        titulo,
        createdAt,
        versiones {
          internalLink,
          version
        }    
      }
    }
  }
`;

export const Seguimientos = gql`
  query Seguimiento($oidProfile: String)  {
    seguimiento(oidProfile: $oidProfile ) {
      seguimiento {
        id,
        realizado,
        esAsesoria,
        fechaVisita,
        empresa,
        comentarios {
          id,
          comentario,
          doneBy,
          fecha
        }     
      }
    }
  }
`;

export const SeguimientosTotal = gql`
  query {
    seguimiento(realizado: true) {
      seguimiento {
        id,
        realizado,
        fechaVisita,
        empresa,
        comentarios {
          id,
          comentario,
          doneBy,
          fecha
        }     
      }
      profile {
        identifier
        usuario
        idPerfil
        nombrePerfil
        abierto
      }
    }
  }
`;

export const SeguimientosFecha = gql`
  query SeguimientosPorFecha($fechaInicio:Date!) {
      seguimiento(initDate:$fechaInicio) {
        profile {
          usuario
        }
        seguimiento {
          id
          profileId
          empresa
          fechaVisita,
          realizado,
          esAsesoria,
          comentarios {
            fecha
            comentario
            doneBy
          }
        }
      }
    }
  
`;

export const MutateAddInforme = gql`
  mutation createInforme($profile:String!,$titulo:String!,$fecha:Date!){
    createInforme(informe:{
      oidProfile: $profile,
      titulo: $titulo,
      fechaInforme: $fecha
    }) {
      informe {
        oid,
        oidProfile,
        fechaInforme,
        titulo,
        createdAt,
        versiones {
          internalLink,
          version
        }
      }
    }
  }
`;

export const MutateAddSeguimiento = gql`
  mutation CreateSeguimiento($profile: String!, $empresa: String!, $fechaVisita: Date!,$realizado: Boolean!, $esAsesoria: Boolean!) {
    createSeguimiento(
      profileId: $profile,
      seguimiento: {
        empresa: $empresa,
        fechaVisita: $fechaVisita,
        realizado: $realizado,
        esAsesoria: $esAsesoria
      }
    ) {
        seguimientos {
          id,
          empresa,
          fechaVisita,
          esAsesoria,
          realizado
      }
    }
  }
`;

export const MutateEditSeguimiento = gql`
    mutation UpdateSeguimiento($oid: String!,$seguimientoUpdate: SeguimientoUpdateInput!) {
      updateSeguimiento(
        oid: $oid,
        seguimiento: $seguimientoUpdate
      ) {seguimiento {
        id,
        profileId,
        empresa,
        fechaVisita,
        realizado,
        esAsesoria
      }}
    }
`;

export const MutateDeleteSeguimiento = gql`
  mutation DeleteSeguimiento($id: String!) {
    deleteSeguimiento(
      oid: $id,
    ) {
      isDeleted
    }
  }
`;

export const Solicitudes = gql`
  query {
    solicitud {
      id,
      nombreCompleto,
      nombreNegocio,
      direccionNegocio,
      email,
      createdAt,
      estado
    }
  }
`;

export const MutateAddSolicitud = gql`
  mutation CrearSolicitud(
    $email: String!,
    $nombreCompleto: String,
  ) {
    createSolicitud(
      solicitud: {
        email: $email,
        nombreCompleto: $nombreCompleto,
    }) {
      solicitud {
        id,
        nombreCompleto,
        email,
        telefono,
        nombreNegocio,
        direccionNegocio,
        dispongoUsuarioIberdrola,
        estado,
        observaciones,
        createdAt,
        updatedAt
      }
    }
  }
`;

export const MutateDeleteSolicitud = gql`
  mutation BorrarSolicitud(
    $id: String!,
  ) {
    deleteSolicitud(
      id: $id
    ) {
      isDeleted
    }
  }
`;

export const MutateAddComercializadora = gql`
  mutation AddComercializadora($profileId: String!,$comercializadora: ComercializadoraInput!) {
    addComercializadora(
      profileId: $profileId,
      comercializadora: $comercializadora
    ) {
      comercializadoras {
        comercializadora
        date
        esAgrupada
      }
    }
  }
`;

export const MutateEditComercializadora = gql`
  mutation ReplaceComercializadora($profileId: String!,$comercializadoraNew: ComercializadoraInput!,$comercializadoraOld: ComercializadoraInput!) {
    replaceComercializadora(
      profileId: $profileId,
      comercializadoraNew: $comercializadoraNew,
      comercializadoraOld: $comercializadoraOld
    ) {
      comercializadoras {
        comercializadora
        date
        esAgrupada
      }
    }
  }
`;

export const MutateDeleteComercializadora = gql`
  mutation DeleteComercializadora($profileId: String!,$comercializadoraOld: ComercializadoraInput!) {
    deleteComercializadora(
      profileId: $profileId,
      comercializadoraOld: $comercializadoraOld
    ) {
      comercializadoras {
        comercializadora
        date
        esAgrupada
      }
    }
  }
`;

export const MutateAddInversion = gql`
  mutation AddInversion($profileId: String!,$inversion: InversionInput!) {
    addInversion(
      profileId: $profileId,
      inversion: $inversion
    ) {
      inversiones {
        inversion
        date
      }
    }
  }
`;

export const MutateEditInversion = gql`
  mutation ReplaceInversion($profileId: String!,$inversionOld: InversionInput!,$inversionNew: InversionInput!) {
    replaceInversion(
      profileId: $profileId,
      inversionOld: $inversionOld,
      inversionNew: $inversionNew
    ) {
      inversiones {
        inversion
        date
      }
    }
  }
`;

export const MutateDeleteInversion = gql`
  mutation DeleteInversion($profileId: String!,$inversionOld: InversionInput!) {
    deleteInversion(
      profileId: $profileId,
      inversionOld: $inversionOld,
    ) {
      inversiones {
        inversion
        date
      }
    }
  }
`;

export const QueryConsumosGraficaMock = gql `
  query ConsumosGraficaMock($primerPeriodo: String!, $segundoPeriodo: String!,$primerValue: Float!,$segundoValue: Float!) {
    graficaConsumoMock(
      primerPeriodoTag: $primerPeriodo,
      segundoPeriodoTag: $segundoPeriodo,
      primerPeriodoValue: $primerValue,
      segundoPeriodoValue: $segundoValue
    ) {
      primerPeriodo {
        value
      }
      segundoPeriodo {
        value
      }
      grafica
    }
  }
`;