<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de informes de agrupadas</h4>
            </div>
            <div class="card-body">
                <ul>
                    <li><a href="../../assets/agrupadas/Informe agrupada 2021.PDF.pdf">Informe agrupada 2021</a></li>
                    <li><a href="../../assets/agrupadas/V06_InformeSmartkalea.pdf">Informe agrupada 2022</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>