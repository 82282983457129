// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hasButtons: false,
  url: 'https://smartkaleaenergia.com/es',
  // urlInformes: 'https://gesletter.duckdns.org:7388/api/v1',
  urlInformes: 'https://trazabilidad.duckdns.org:7388/api/v1', //Para el docker
  urlDjagoKalea: 'https://djangokalea.duckdns.org'
};

// export const environment = {
//   production: true,
//   hasButtons: false,
//   url: 'https://smartkaleaenergia.com/es',
//   // urlInformes: 'https://gesletter.duckdns.org:7388/api/v1',
//   urlInformes: 'http://localhost:8876/api/v1', //Para el docker
//   urlDjagoKalea: 'http://176.58.14.58'
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
