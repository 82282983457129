import { Component, OnInit } from '@angular/core';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  constructor(
    public usuarioService: UsuariosService
  ) { }

  ngOnInit() {
  }

  logout() {
    this.usuarioService.logout();
  }

}
