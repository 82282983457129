import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroInactivo',
  pure: false
})
export class FiltroInactivoPipe implements PipeTransform {

  transform(array: any[], activo: boolean, inactivo: boolean ): any[] {

    // if (!array) {
    //   return array;
    // }

    // if (!esAsesoria) {
    //   return array.filter(
    //     item => {
    //       console.log('esta no es Asesorias', item.seguimiento[columna]);
    //       return !item.seguimiento[columna]
    //     });
    // } else if (!esSeguimiento) {
    //   return array.filter(
    //     item => {
    //       console.log('esta no es Seguimiento', item.seguimiento[columna]);
    //       return item.seguimiento[columna]
    //   });
    // } 
    // else {
    //   return array;
    // }

    // console.log('ITEMS RECIBIDOS',array);
    // return array;

    if (!array) {
      return array;
    }

    if(inactivo && activo) {
      //Devolvemos todo el array, se incluyen todos
      return array;
    } else if (!inactivo && activo) {
      return array.filter(
        item => {
          return item.abierto;
        }
      );
    } else if(inactivo && !activo) {
      return array.filter(
        item => {
          return !item.abierto;
        }
      );
    } else if(!inactivo && !activo) {
      return array.filter(
        item => {
          return false;
        }
      );
    } else {
      return array;
    }

  }

}
