import { Injectable } from '@angular/core';
import { getDescription } from 'graphql';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Comentario, MejoraPropuesta, Usuario } from '../interfaces/interfaces';
import { fondo, logo, moda, alimentacion, hosteleria, pelu, otros, is_trending_up, is_trending_down, is_trending_same } from './imagenes';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfMakeService {

  constructor() {}

  generatePdf(cuenta: Usuario, _image: any, consumo2019: number, consumo2020: number, potencia: string, tarifa: string, fecha: any) {
    // mapeo que mejoras son de potencia y cuales no    
    let mejorasPotencia: MejoraPropuesta[] = [];
    let mejorasNormales: MejoraPropuesta[] = [];
    let fechaInforme;
    let meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
    

    cuenta.mejoras.map(mejora => {
      if (mejora.tag !== null ) {
        if (mejora.tag === 'potencia') {
          mejorasPotencia.push(mejora);
        }
      } else {
        mejorasNormales.push(mejora);
      }
    });

    // Calculo del %
    let trending_image = is_trending_up;
    let porcentaje_ahorro = 0;
    porcentaje_ahorro =
       100 - (consumo2020 / consumo2019) * 100;

    if (consumo2019 >= consumo2020) {
      console.log('Ha disminuido el consumo');
      trending_image = is_trending_down;
      porcentaje_ahorro = porcentaje_ahorro * -1.0;
    } else {
      console.log('Aumentó el consumo');
      trending_image = is_trending_up;
      porcentaje_ahorro = Math.abs(porcentaje_ahorro);
    }
    
    //Calculo de fecha del informe
    let indice: any;
    indice = (fecha.substring(5,7)-1);
    let anio: any;
    anio = fecha.substring(0,4);
    if (indice == 0){
      anio -=1;
      fechaInforme = meses[11] + ' '+ anio;
    }
    else{
      //Comentado, este mes se genera el informe del mes en curso
      // indice -=1; //Resto -1 porque suelen generar los informes de un mes el mes siguiente
      indice +=1; //Comentario para Camino para generar los informes de Diciembre
      if(indice > 11) {
        indice = 11;
      }
      fechaInforme = meses[indice] + ' '+ anio;
    }
            
    //console.log('Fecha del informe para pdf',fechaInforme);
    //
    //if (fecha > mejorasPotencia[0].date){
    //  console.log(fechaInforme);
    //}
    //console.log(mejorasNormales[0].descripcion);
    console.log(mejorasNormales);
    console.log(mejorasPotencia);
      
      //Ordenación de fechas
      //let sortedArrayMP = mejorasPotencia.sort( (elementA, elementB) => elementA.date > elementB.date);
      //let sortedArrayMN = mejorasNormales.sort();
      //let sortedArrayComent = cuenta.comentarios.sort();


    //Calculo de mejoras y comentarios a imprimir en pdf
    let mejoraNormalReciente: any = '';
    let mejoraPotenciaReciente: any = '';
    let comentarioReciente: any = '';
  
    //Debería tener ordenados por fecha los arrays 

    mejorasNormales.forEach( (m: any) => {
      if( fecha >= m.date){
        mejoraNormalReciente = m.descripcion;
      }
    });

    mejorasPotencia.forEach( (j: any) =>{
      if( fecha >= j.date){
        mejoraPotenciaReciente = j.descripcion;
      }
    });

    cuenta.comentarios.forEach( (k: any) =>{
      if( fecha >= k.date){
        comentarioReciente = k.comentario;
      }
    });

  
    //console.log(mejorasNormales[0].date);
    //console.log(mejorasNormales[1].date);
    //console.log(mejorasNormales[2].date);
    //console.log(mejorasPotencia[0].date);
    //console.log(mejorasPotencia[1].date);
    //console.log(mejorasPotencia[2].date);

    
    let tituloInforme = cuenta.tituloInforme ? cuenta.tituloInforme.toUpperCase() : cuenta.usuario.toUpperCase();

    let imageActividad = cuenta.actividad === 'Otros' ? otros : moda;

    let estiloTitulo = 'titulo';
    let zona = cuenta.zona;
    let descripcion = cuenta.descripcion;

    if (zona == 'D_SSABIO') {
      zona = 'Sancho el Sabio';
    }

    if (cuenta.actividad == 'Alimentacion') {
      imageActividad = alimentacion;
    }
    if (cuenta.actividad == 'Hosteleria') {
      imageActividad = hosteleria;
    }
    if (cuenta.actividad == 'Pelu') {
      imageActividad = pelu;
    }

    if (tituloInforme == 'ARRATOLE_IBERDROLA') {
      tituloInforme = 'MUEBLES ARRATOLE';
    }
    if (tituloInforme == 'COLCHONERIA ALONSO') {
      tituloInforme = 'COLCHONES ALONSO';
    }
    if(tituloInforme == 'FARMACIA GASTELURRUTIA') {
      estiloTitulo = 'tituloPequeño';
      fechaInforme = 'DICIEMBRE 2021';
      
      mejoraPotenciaReciente = 'Debido a la pandemia, durante el año 2020, se compara el consumo del año 2021 con el año 2019. El consumo energético es menor.';
      mejoraNormalReciente = 'La mayor parte de la iluminación del comercio es LED. Se propone la sustitución de 2 regletas fluorescentes de 3x18W por pantallas fluorescentes de 24W.';
    }

    if(tituloInforme == 'CARNICERIA J SANTOS')
    {
      estiloTitulo = 'tituloMenor';
    }
    if(tituloInforme == 'INMOBILIARIA ETXEBERRIA') {
      estiloTitulo = 'tituloPequeño';

      descripcion = 'Gestión de bienes inmuebles y consultoría.';
      mejoraPotenciaReciente = 'Aunque debido a la pandemia, durante el año 2020 se compara el consumo del año 2021 con el año 2019, no se pudieron recuperar consumos de 2019. Se ha experimentado un incremento con respecto a 2020.'
      comentarioReciente = 'La agrupación de consumidores eléctricos, impulsada por el programa SmartKalea, ha permitido a Inmobiliaria Etxeberria reducir el gasto anual de electricidad en 167 €, lo que supone un ahorro del 44%. Se recomienda el uso de la aplicación móvil Smartkalea para hacer seguimiento de los datos referentes al consumo de electricidad con más detalle';

      //MODIFICAR CALCULO
      porcentaje_ahorro =
      100 - (1258 / 973) * 100;

      if (973 >= 1258) {
        console.log('Ha disminuido el consumo');
        trending_image = is_trending_down;
        porcentaje_ahorro = porcentaje_ahorro * -1.0;
      } else {
        console.log('Aumentó el consumo');
        trending_image = is_trending_up;
        porcentaje_ahorro = Math.abs(porcentaje_ahorro);
      }

    }

    if(tituloInforme == 'URBANA') {
      comentarioReciente = 'Este comercio no se encuentra en la compra agrupada de energía. En caso de adherirse, se estima que reduciría su gasto anual de electricidad en 498 €, lo que supone un ahorro del 41%. Se recomienda el uso de la aplicación móvil Smartkalea para hacer seguimiento de los datos referentes al consumo de electricidad con más detalle.'
    }

    if(tituloInforme == 'CASAFLAME') {
      tituloInforme = 'CASA FLAME';
      descripcion = 'Restaurante de comida turca especializado en falafel, doner kebab y pollo frito.';
    }

    if(tituloInforme == 'MUEBLES ARMINZA') {
      trending_image = is_trending_same;
      porcentaje_ahorro = 0;

      mejoraPotenciaReciente = 'No se han podido recuperar datos de consumo por fallo de acceso en la plataforma distribuidora.'
    }

    if(tituloInforme == 'BAR EL ONCE') {
      trending_image = is_trending_same;
      porcentaje_ahorro = 0;
    }

    if(tituloInforme == 'IBÉRICOS DELUXE') {
      trending_image = is_trending_same;
      porcentaje_ahorro = 0;

      mejoraPotenciaReciente = 'No se han podido recuperar datos de consumo por fallo de acceso en la plataforma distribuidora.'
    }


    let contentRaw: any[] = [
      {
        columns: [
          {
            image: logo,
            width: 270,
            absolutePosition: { x: 15, y: 70 }
          },
          [
            {
              text: tituloInforme,
              style: estiloTitulo,
              absolutePosition: { x: 35, y: 130 }
            },
            {
              text: fechaInforme, //fechaInforme
              style: 'headerFecha',
              alignment: 'center',
              absolutePosition: { x: 469, y: 140 } // Posicion original: 460, 150
            },
            { canvas: [{ type: 'line', x1: 390, y1: -1, x2: 510, y2: -150, lineWidth: 2 }] },

            {
              text: descripcion,
              margin: [0, 0, 0, 0],
              absolutePosition: { x: 30, y: 200 }
            },
            {
              text: [{ text: 'Zona: ', bold: true }, { text: zona }],
              absolutePosition: { x: 300, y: 350 }
            },
            {
              text: [{ text: 'Superficie (m2): ', bold: true }, { text: cuenta.superficie }],
              absolutePosition: { x: 450, y: 350 }
            },
            {
              text: [{ text: 'Potencia cont. (kW): ', bold: true }, { text: potencia }],
              absolutePosition: { x: 300, y: 370 }
            },
            {
              text: [{ text: 'Tarifa: ', bold: true }, { text: tarifa }],
              absolutePosition: { x: 450, y: 370 }
            },
            {
              text: mejoraPotenciaReciente,
              absolutePosition: { x: 310, y: 410 }
            },
            {
              image: _image ? _image : logo,
              fit: [225, 225],
              absolutePosition: { x: 35, y: 295 }
            },
            {
              image: trending_image,
              fit: [70, 70],
              absolutePosition: { x: 420, y: 270 }
            },
            {
              image: imageActividad,
              fit: [50, 50],
              absolutePosition: { x: 415, y: 130 }
            },
            {
              canvas: [
                {
                  type: 'rect',
                  x: -260,
                  y: 120,
                  w: 550,
                  h: 60,
                  r: 5,
                  lineColor: '#cb222b'
                },
                {
                  type: 'rect',
                  x: 20,
                  y: 330,
                  w: 270,
                  h: 100,
                  r: 5,
                  lineColor: '#cb222b'
                },
                {
                  type: 'rect',
                  x: -260,
                  y: 490,
                  w: 550,
                  h: 80,
                  r: 5,
                  lineColor: '#cb222b'
                },
                {
                  type: 'rect',
                  x: -260,
                  y: 610,
                  w: 550,
                  h: 75,
                  r: 5,
                  lineColor: '#cb222b'
                }
              ]
            }
          ]
        ]
      },

      {
        text: mejorasNormales.length === 0 ? '' : mejoraNormalReciente,
        margin: [0, 0, 0, 25],
        absolutePosition: { x: 30, y: 565 }
      },
      {
        text:
          comentarioReciente,
        margin: [0, 0, 0, 25],
        absolutePosition: { x: 30, y: 685 }
      },
      // {
      //   text: 'El comercio no forma parte de la Agrupación de Consumidores impulsada por Smartkalea. En su caso, el ahorro por precio Agrupado sería del 32% en el término de energía.',
      //   margin: [0, 0, 0, 25],
      //   absolutePosition: { x: 30, y: 685 }
      // },
      {
        text: 'Evolución',
        style: 'greatHeader',
        absolutePosition: { x: 300, y: 300 }
      },
      {
        text: [{ text: porcentaje_ahorro.toFixed(0) }, { text: ' %' }],
        style: 'header',
        absolutePosition: { x: 500, y: 295 }
      },
      {
        text: 'Datos de consumo',
        style: 'greatHeader',
        absolutePosition: { x: 30, y: 270 }
      },

      {
        text: 'Medidas a implementar',
        style: 'greatHeader',
        absolutePosition: { x: 30, y: 530 }
      },
      {
        text: 'Se contemplan periodos anuales de Diciembre a Noviembre',
        style: 'note',
        absolutePosition: {x: 85, y: 516}
      },

      {
        text: 'Comentarios',
        style: 'greatHeader',
        absolutePosition: { x: 30, y: 650 }
      },

      // {
      //   text: cuenta.mejoras[0].descripcion,
      //   absolutePosition: { x: 30, y: 640 }
      // },
      // {
      //   text: cuenta.comentarios[0].comentario,
      //   absolutePosition: { x: 30, y: 680 }
      // }
    ];
    
    

    // cuenta.mejoras.forEach((mejora: MejoraPropuesta, pos: number) => {
    //   contentRaw.push({
    //     text: [
    //       {
    //         text: mejora.descripcion,
    //         absolutePosition: { x: 30, y: 640 }
    //       }
    //     ]
    //   });
    // });

    // cuenta.comentarios.forEach((comentario: Comentario, pos: number) => {
    //   contentRaw.push({
    //     text: [
    //       {
    //         text: comentario.comentario,
    //         absolutePosition: { x: 30, y: 680 }
    //       }
    //     ]
    //   });
    // });

    const documentDefinition = {
      background: {
        image: fondo,
        width: 595
      },
      pageMargins: [10, 30, 30, 10],
      content: contentRaw,
      styles: {
        centerme: {
          alignment: 'center'
        },
        titulo: {
          fontSize: 37,
          margin: [0, 10, 0, 10],
          bold: true
        },
        tituloMenor:  {
          fontSize: 35,
          margin: [0, 10, 0, 10],
          bold: true
        },
        tituloPequeño:  {
          fontSize: 29,
          margin: [0, 10, 0, 10],
          bold: true
        },
        greatHeader: {
          fontSize: 22,
          color: '#cb222b',
          margin: [0, 10, 0, 10],
          bold: true
        },
        header: {
          fontSize: 18,
          margin: [0, 10, 0, 10],
          bold: true
        },
        headerFecha: {
          fontSize: 18,
          margin: [0, 10, 0, 10],
          bold: true
        },
        note: {
          fontSize: 6,
          margin: [0, 10, 0, 10],
          color: '#616161'
        }
      }
    };
    pdfMake.createPdf(documentDefinition).open();
  }

}
