import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroAgrupada',
  pure: false
})
export class FiltroAgrupadaPipe implements PipeTransform {

  transform(array: any[], dentroAgrupada: boolean, fueraAgrupada: boolean ): any[] {

    // if (!array) {
    //   return array;
    // }

    // if (!esAsesoria) {
    //   return array.filter(
    //     item => {
    //       console.log('esta no es Asesorias', item.seguimiento[columna]);
    //       return !item.seguimiento[columna]
    //     });
    // } else if (!esSeguimiento) {
    //   return array.filter(
    //     item => {
    //       console.log('esta no es Seguimiento', item.seguimiento[columna]);
    //       return item.seguimiento[columna]
    //   });
    // } 
    // else {
    //   return array;
    // }

    // console.log('ITEMS RECIBIDOS',array);
    if (!array) {
      return array;
    }

    if(dentroAgrupada && fueraAgrupada) {
      //Devolvemos todo el array, se incluyen todos
      return array;
    } else if (!dentroAgrupada && fueraAgrupada) {
      return array.filter(
        item => {
          return !item.esAgrupada;
        }
      );
    } else if(dentroAgrupada && !fueraAgrupada) {
      return array.filter(
        item => {
          return item.esAgrupada;
        }
      );
    } else if(!dentroAgrupada && !fueraAgrupada) {
      return array.filter(
        item => {
          return false;
        }
      );
    } else {
      return array;
    }

  }

}
