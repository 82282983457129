import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Profile } from 'src/assets/querys/querysGraphql';


@Component({
  selector: 'app-visita',
  templateUrl: './visita.component.html',
  styleUrls: ['./visita.component.scss']
})
export class VisitaComponent implements OnInit {

  usuario: any;

  constructor(
    private apollo: Apollo,
  ) { }

  ngOnInit(): void {

      // this.apollo.watchQuery(
      //   { query: Profile,
      //     variables: {
      //       usuario: this.usuarioId
      //     }
      //   })
      //   .valueChanges.subscribe((result: any) => {
      //     console.log(result);
      //     this.usuario = result.data.profiles[0];
      //   });

  }

}
