<div class="card">
    <div class="card-body">
        <h3 class="card-title">{{ titulo }}</h3>
        <div style="display: block">
          <canvas baseChart
          height="200"
            [data]="doughnutChartData"
            [labels]="doughnutChartLabels"
            [colors]="colors"
            [chartType]="doughnutChartType">
          </canvas>
        </div>
    </div>
</div>
