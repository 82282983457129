import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Comentario, MejoraPropuesta, Usuario } from '../interfaces/interfaces';
import {logo, moda, alimentacion, hosteleria, pelu, otros, is_trending_up, is_trending_down } from './imagenes';

import { background } from './informes_assets/background';
import { footerLogos } from './informes_assets/footer-logos';

import { logoLetter } from './informes_assets/logo-letter';
import { logosConcejalia } from './informes_assets/logos-concejalia';
import { checkTick } from './informes_assets/check_tick';
import { checkXCross } from './informes_assets/check_xcross';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfInformeSeguimientoService {

  constructor() { }

  generateMock(cuenta: Usuario,potencia: string,tarifa: string,graficaConsumo:string,value1: number, value2: number,nombre: string|undefined,evolucion_potencia: string|undefined,mejoras_previas: string|undefined,nuevas_recomendaciones: string|undefined,comentario_adicional: string|undefined) {

    let texto_seguimiento = 'En la anterior visita se recomendó cambiar la iluminación a LEDs. Se han reemplazado 4 proyectores de descarga por proyectores LED de 38W.';
    let texto_potencia = 'La potencia contratada es correcta. Se recomienda cambiar la tarifa a una tarifa más competitiva como la 3.0TD.';
    if(evolucion_potencia !== undefined) {
      texto_potencia = evolucion_potencia;
    }
    if(mejoras_previas !== undefined) {
      texto_seguimiento = mejoras_previas;
    }

    this.generatePdf(cuenta,potencia,tarifa,'2023-03-01',graficaConsumo,value1,value2,texto_seguimiento,true,false,texto_potencia,nombre,nuevas_recomendaciones,comentario_adicional);
  }

  generatePdf(
    cuenta: Usuario,
    potencia: string,
    tarifa: string, 
    fecha: any,
    grafica: string,
    periodoA: number, 
    periodoB: number,
    seguimiento: string,
    potencia_correcta: boolean, 
    tarifa_correcta: boolean,
    comentario_potencia: string,
    nombre_comercio: string|undefined,
    nuevas_recomendaciones: string|undefined,
    comentario_adicional: string|undefined){ 
    let mejorasNormales: MejoraPropuesta[] = [];
    let fechaInforme;
    let meses = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];
    
    cuenta.mejoras.map(mejora => {
      if (mejora.tag !== null ) {
        if (mejora.tag === 'potencia') {
        }
      } else {
        mejorasNormales.push(mejora);
      }
    });

    //Calculo de fecha del informe
    let indice: any;
    let anio: any;
    indice = (fecha.substring(5,7)-1); 
    anio = fecha.substring(0,4);
    // if (indice == 0){
    //   anio -=1;
    //   fechaInforme = meses[11] + ' '+ anio;
    // }
    // else{
    // //   indice -=1; //Resto -1 porque suelen generar los informes de un mes el mes siguiente
    //   fechaInforme = meses[indice] + ' '+ anio;
    // }
    fechaInforme = meses[indice] + ' ' + anio;

    ///////////////////////////////////////
    // TENDENCIA
    ///////////////////////////////////////

    let logo_tendencia = is_trending_down;
    let evolucion = periodoA - periodoB;
    const trending_up = 1;
    const trending_down = 0;

    let trending: boolean = true;
    let percent = 0;

    let check_potencia = checkTick;

    if(potencia_correcta == false) {
      check_potencia = checkXCross;
    }

    let check_tarifa = checkTick;
    if(tarifa_correcta==false) {
      check_tarifa = checkXCross;
    }

    evolucion = Math.round(evolucion + Number.EPSILON)

    if(evolucion > 0) {
      logo_tendencia = is_trending_down;
      trending = false;
      percent = (evolucion / periodoA) * 100.0;
      percent = Math.round(percent + Number.EPSILON);
    }
    if(evolucion < 0) {
      logo_tendencia = is_trending_up;
      trending = true;
      percent = (Math.abs(evolucion) / periodoA) * 100.0;
      percent = Math.round(percent + Number.EPSILON);

    }
    ///////////////////////////////////////

    
    //Calculo de mejoras y comentarios a imprimir en pdf
    let mejoraNormalReciente: any = '';
    let comentarioReciente: any = '';
      
    //La mejora a imprimir es la ultima que se puso antes de la fecha de creación del seguimiento
    //Deberia ordenar los arrays de mejoras,coment,etc por fecha de menor a mayor (POR HACER)

    mejorasNormales.forEach( (m: any) => {
      if( fecha >= m.date){
        mejoraNormalReciente = m.descripcion;
      }
    });

    cuenta.comentarios.forEach( (k: any) =>{
      if( fecha >= k.date){
        comentarioReciente = k.comentario;
      }
    });
      
    //console.log(mejorasNormales[0].date);
    //console.log(mejorasNormales[1].date);
    //console.log(mejorasNormales[2].date);
    //console.log(mejorasPotencia[0].date);
    //console.log(mejorasPotencia[1].date);
    //console.log(mejorasPotencia[2].date);

    const titulo_informe = 'INFORME DE SEGUIMIENTO EN EFICIENCIA ENERGÉTICA,';
    let nombre_informe = cuenta.tituloInforme ? cuenta.tituloInforme.toUpperCase() : cuenta.usuario.toUpperCase();

    if(nombre_comercio !== undefined) {
      nombre_informe = nombre_comercio.toUpperCase();
    }

    const descripcion_informe = 'En este informe se recoge la información principal sobre la situación energética de la empresa tras haber realizado el seguimiento del estado de las instalaciones energéticas, la tarifa eléctrica contratada y haber monitorizado el consumo eléctrico.';

    let texto_recomendaciones = 'Se recomienda continuar con la sustitución de la iluminación a LEDs, reemplazando 11 lámparas dicroicas halógenas por LED de 9W.'

    if(nuevas_recomendaciones !== undefined) {
      texto_recomendaciones = nuevas_recomendaciones;
    }

    let comentario_final= cuenta.comentarios.length === 0 ? '' : comentarioReciente;
    if(comentario_adicional !== undefined) {
      comentario_final = comentario_adicional;
    }

    const texto_logos = 'Proyecto ejecutado en el marco del Plan de Recuperación, Transformación y Resiliencia, y financiado por la Unión Europea-Next Generation EU y por el Ministerio de Industria, Comercio y Turismo, y por el Departamento de Turismo, Comercio y Consumo del Gobierno Vasco.'
    let contentRaw: any[] = [
      {
        columns: [
          {
            image: logoLetter,
            width: 60,
            // absolutePosition: { x: 15, y: 70 }
            absolutePosition: {x: 35, y: 38}
          },    
          // {
          //   image: logo,
          //   width: 200,
          //   // absolutePosition: { x: 15, y: 70 }
          //   absolutePosition: {x: 30, y: 20}
          // },
    
          [
            {
              text: titulo_informe,
              style: 'header',
              // absolutePosition: { x: 35, y: 150 }
              absolutePosition: { x: 35, y: 90}
            },
            {
              text: nombre_informe,
              style: 'header',
              // absolutePosition: { x: 35, y: 150 }
              absolutePosition: { x: 35, y: 115}
            },
            {
              text: fechaInforme, //fechaInforme
              style: 'footerFecha',
              alignment: 'left',
              // absolutePosition: { x: 415, y: 160 }
              absolutePosition: { x: 35, y: 808}
            },
            // { canvas: [{ type: 'line', x1: 390, y1: -1, x2: 510, y2: -150, lineWidth: 2 }] },

            // {
            //   image: otros,
            //   width: 25,
            //   absolutePosition: {x: 415,y: 110}
            // },
      
            {
              text: descripcion_informe,
              margin: [0, 0, 0, 0],
              alignment: 'justify',
              // absolutePosition: { x: 30, y: 230 }
              absolutePosition: {x: 35, y: 155}
            },
            {
              text: [{ text: 'Zona: ', bold: true }, { text: cuenta.zona }],
              // absolutePosition: { x: 120, y: 305 }
              absolutePosition: {x: 329, y: 280}
            },
            {
              text: [{ text: 'Superficie (m2): ', bold: true }, { text: cuenta.superficie }],
              // absolutePosition: { x: 280, y: 305 }
              absolutePosition: {x: 460, y: 280}
            },
            {
              text: [{ text: 'Potencia cont. (kW): ', bold: true }, { text: potencia }],
              // absolutePosition: { x: 120, y: 335 }
              absolutePosition: {x: 329, y:300}
            },
            {
              text: [{ text: 'Tarifa: ', bold: true }, { text: tarifa }],
              // absolutePosition: { x: 280, y: 335 }
              absolutePosition: {x: 329,y: 320}
            },
            // {
            //   image: check_potencia,
            //   width: 10,
            //   absolutePosition: {x: 470, y: 302}
            // },
            // {
            //   text: potencia_correcta ? 'Ajustada' : 'Incorrecta',
            //   bold: true,
            //   margin: [0,0,0,0],
            //   absolutePosition: {x: 484, y: 300}
            // },
            // {
            //   text: tarifa_correcta ? 'Competitiva' : 'Incorrecta',
            //   bold: true,
            //   margin: [0,0,0,0],
            //   absolutePosition: {x: 484, y: 319}
            // },
            // {
            //   image: check_tarifa,
            //   width: 10,
            //   absolutePosition: {x: 470, y: 320}
            // },
            // {
            //   image: cuenta.actividad === 'Otros' ? otros : moda,
            //   fit: [50, 50],
            //   absolutePosition: { x: 415, y: 130 }
            // },
            {
              canvas: [
                {
                  type: 'rect',
                  x: -42,
                  y: 60,
                  w: 550,
                  h: 60,
                  r: 5,
                  lineColor: '#cb222b'
                },
                
                {
                  type: 'rect',
                  x: -42,
                  // y: 340,
                  y: 505,
                  w: 550,
                  h: 60,
                  r: 5,
                  lineColor: '#cb222b'
                },
                {
                  type: 'rect',
                  x: 255,
                  // y: 340,
                  y: 262,
                  w: 255,
                  h: 100,
                  r: 5,
                  lineColor: '#cb222b'
                },
                {
                  type: 'rect',
                  x: -42,
                  // y: 340,
                  y: 405,
                  w: 550,
                  h: 60,
                  r: 5,
                  lineColor: '#cb222b'
                },

                {
                  type: 'rect',
                  x: -42,
                  y: 605,
                  w: 550,
                  h: 85,
                  r: 5,
                  lineColor: '#cb222b'
                }
              ]
            }
          ]
        ]
      },
      {
        image: logosConcejalia,
        width: 300,
        absolutePosition: {x: 320,y: -10}
      },
      // {
      //   image: logoLetter,
      //   width: 60,
      //   // absolutePosition: { x: 15, y: 70 }
      //   absolutePosition: {x: 260, y: 30}
      // },
      {
        image: logo,
        width: 200,
        // absolutePosition: { x: 15, y: 70 }
        absolutePosition: {x: 118, y: 30}
      },

      {
        image: grafica,
        width: 300,
        absolutePosition: {x: 35 ,y: 250}
      },

      {
        // text: mejorasNormales.length === 0 ? '' : mejoraNormalReciente,
        text: texto_recomendaciones,
        margin: [0, 0, 0, 25],
        alignment: 'justify',
        // absolutePosition: { x: 30, y: 420 }
        absolutePosition: {x: 35, y: 600}
      },
      // {
      //   text: 'En la anterior visita se recomendó cambiar la iluminación a LEDs. Se han reemplazado 4 proyectos de descarga por proyectores LED de 38W.',
      //   margin: [0, 0, 0, 25],
      //   alignment: 'justify',
      //   // absolutePosition: { x: 30, y: 420 }
      //   absolutePosition: {x: 35, y: 600}
      // },

      {
        text: comentario_final,
        margin: [0, 0, 0, 25],
        alignment: 'justify',
        // absolutePosition: { x: 30, y: 620 }
        absolutePosition: {x: 35, y: 700}
      },
      // {
      //   text: 'Se propone continuar con la actualización LED, acometiendo la sustitución de 11 lámparas dicróicas halógenas por LED de 9W.',
      //   margin: [0, 0, 0, 25],
      //   alignment: 'justify',
      //   // absolutePosition: { x: 30, y: 620 }
      //   absolutePosition: {x: 35, y: 700}
      // },

      {
        text:
          seguimiento,
        margin: [0, 0, 0, 25],
        alignment: 'justify',
        // absolutePosition: { x: 30, y: 620 }
        absolutePosition: {x: 35, y: 500}
      },
      {
        text: comentario_potencia,
        width: '10%',
        margin: [0, 0, 0, 25],
        alignment: 'justify',
        // absolutePosition: { x: 30, y: 620 }
        absolutePosition: {x: 335, y: 358}
      },

      {
        image: trending ? is_trending_up : is_trending_down,
        width: 60,
        absolutePosition: {x: 426, y: 212}
      },

      {
        text: [{ text: percent, style: "greatHeaderBlack"}, { text: '%', style: "greatHeaderBlack"}],
        absolutePosition: { x: 490, y: 230 }
      },

      {
        text: 'Gráfica de consumo',
        style: 'greatHeader',
        // absolutePosition: { x: 30, y: 370 }
        absolutePosition: {x: 30, y: 230}
      },
  
      {
        text: 'Evolución',
        style: 'greatHeader',
        // absolutePosition: { x: 30, y: 370 }
        absolutePosition: {x: 330, y: 230}
      },
      {
        text: 'Medidas de mejora sugeridas previamente e implementadas',
        style: 'greatHeader',
        absolutePosition: {x: 30, y: 470}
      },

      {
        text: 'Recomendación de nuevas medidas de mejora',
        style: 'greatHeader',
        // absolutePosition: { x: 30, y: 370 }
        absolutePosition: {x: 30, y: 569}
      },

      {
        text: 'Comentarios adicionales',
        style: 'greatHeader',
        absolutePosition: { x: 30, y: 670 }
      },
      {
        image:footerLogos,
        absolutePosition: {x: 122, y: 800 },
        width: 400
      },
      {
        text: texto_logos,
        absolutePosition: {x: 35, y: 788},
        style: 'footerText',
        // width: 300
      }
    ];

    const documentDefinition = {
      background: {
        image: background,
        width: 597
      },
      pageMargins: [10, 30, 30, 10],
      content: contentRaw,
      styles: {
        centerme: {
          alignment: 'center'
        },
        titulo: {
          fontSize: 37,
          margin: [0, 10, 0, 10],
          bold: true
        },
        greatHeader: {
          fontSize: 18,
          color: '#cb222b',
          margin: [0, 10, 0, 10],
          bold: true
        },
        greatHeaderBlack: {
          fontSize: 20,
          margin: [0, 10, 0, 10],
          bold: true
        },
        header: {
          fontSize: 20,
          margin: [0, 10, 0, 10],
          bold: true
        },
        footerFecha: {
          fontSize: 10,
          margin: [0,10,0,10],
          bold: true
        },
        footerText: {
          fontSize: 6,
          margin: [0,10,0,10],
          alignment: 'center',
          color: '#333333',
        }
      }
    };
    pdfMake.createPdf(documentDefinition).open();
  }

}
