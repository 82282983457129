import { environment } from './../../../environments/environment';
import { DatePipe, NgSwitchDefault } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { KaleaConsumosChartComponent } from 'src/app/components/kalea-consumos-chart/kalea-consumos-chart.component';
import { Comentario, MejoraPropuesta, ConsumoAnualKalea, Informe, Inversion, Comercializadora } from 'src/app/interfaces/interfaces';
import { ConsumosService } from 'src/app/services/consumos.service';
import { PdfMakeService } from 'src/app/services/pdf-make.service';
import { PdfMakeService2 } from 'src/app/services/pdf-make-tipoInforme2.service'; 
import { UsuariosService } from 'src/app/services/usuarios.service';
import { CalidadDato, Consumo, Contrato, Cups, Informes, Profile, Seguimientos } from 'src/assets/querys/querysGraphql';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComentarioComponent } from 'src/app/components/comentario/comentario.component';
import { MejoraComponent } from 'src/app/components/mejora/mejora.component';
import { UploadInformeComponent } from 'src/app/components/upload-informe-component/upload-informe-component.component';
import Swal from 'sweetalert2'
import { hasJSDocParameterTags } from 'typescript';
import { ComercializadoraComponent } from 'src/app/components/comercializadora/comercializadora.component';
import { PdfInformeSeguimientoService } from 'src/app/services/pdf-informe-seguimiento.service';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';


import { noConsumos } from 'src/app/services/informes_assets/no_consumos';
declare var jQuery:any;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit, AfterViewInit {

  public formEnviado = false;
  usuario: any;
  usuarioId: string = '';
  urlMaps: string = '';
  cups = '';
  potencia = '';
  tarifa = '';
  direccion = '';
  ahorro_agrupada : number = 0;
  ahorro_string : String = "209,75";
  precioAgrupada: number = 0;
  precioNoAgrupada: number = 0;
  modoInformes: boolean =  environment.hasButtons;
  fechaIniContrato: string = '';
  fechaFinContrato: string = '';

  zonas = ['Alde Zaharra', 'Altza', 'Sancho el Sabio', 'San Martín', 'Txomin', 'Cemei', 'Zentek', 'D_BELARTZA', 'Lorea', 'Gros', 'Antiguo', 'Ocio Turistico', 'Otros'];


  newComentario = {
    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    comentario: ''
  };

  newInversion = {
    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    inversion: ''
  };

  newComercializadora = {
    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    comercializadora: '',
    esAgrupada: true
  };

  comentarios: Comentario[] = [];
  comercializadoras: Comercializadora[] = [];
  tablaComercializadora: any[] = [];
  inversiones: Inversion[] = [];

  newInforme = {
    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    titulo: ''
  };
  newSeguimiento = {
    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    empresa: 'Letter Ingenieros',
    esAsesoria: false,
    realizado: false
  };
  informes: any[] = [];
  seguimientos: any[] = [];

  newMejora = {
    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    titulo: '',
    tag: '',
    descripcion: ''
  };

  //INFORME DE SEGUIMIENTO

  newDataInforme = {
    inicioPeriodoUno: this.datePipe.transform(new Date('2021-01-01'), 'yyyy-MM-dd'),
    finPeriodoUno: this.datePipe.transform(new Date('2021-12-31'), 'yyyy-MM-dd'),
    inicioPeriodoDos: this.datePipe.transform(new Date('2022-01-01'), 'yyyy-MM-dd'),
    finPeriodoDos: this.datePipe.transform(new Date('2022-12-31'),'yyyy-MM-dd'),
    overrideValueUno: false,
    overrideValueDos: false,
    overrideNombre: false,
    overridePotencia: false,
    overrideMejorasPrevias: false,
    overrideNuevasRecomendaciones: false,
    overrideComentarioAdicional: false,
    overrideConsumos: false,
    comentario_adicional: '',
    nuevas_recomendaciones: '',
    mejoras_previas: '',
    evolucion_potencia: '',
    nombre: '',
    valueUno: 0,
    valueDos: 0,
  };

  ///
  ultimoSeguimiento: any;
  ultimoInforme = { fecha: '', titulo: '', vrsiones: []};
  mejoras: MejoraPropuesta[] = [];
  consumos: ConsumoAnualKalea[] = [];
  consumo2019 = 0;
  consumo2020 = 0;
  consumo2021 = 2000;
  calidad2020: any = [
    {
      'numDias': 0,
      'porcentajeCorrecto': 0,
      'totalEstimado': 0

    }
  ];
  calidad2021: any = [
    {
      'numDias': 0,
      'porcentajeCorrecto': 0,
      'totalEstimado': 0,
      'totalFallo': 0,
      'totalNulo': 0,
      'totalZero': 0
    }
  ];

  dataArea: number[] = [20,40,40,60,80,50,60,40,80,60,60,80];
  data: any;
  dataConsumoMes: any;
  dataPotencia: any;
  dataMes: any;

  public updateForm = this.fb.group({
    identifier: [''],
    usuario: ['', [ Validators.required ]],
    cuentaUsuario: ['', [ Validators.required ]],
    telefono: ['', ],
    password: ['', [ Validators.required ]],
    superficie: ['', [ Validators.required ]],
    zona: ['', [ Validators.required ]],
    actividad: ['', [ Validators.required ]],
    descripcion: ['', ]
  });

  public updateFormLocalizacion = this.fb.group({
    identifier: [''],
    longitud: ['', ],
    latitud: ['', ]
  });

  public updateFormBooleanos = this.fb.group({
    identifier: [''],
    agrupada: [false, [ Validators.required ]],
    activo: [false, [ Validators.required ]],
  });

  @ViewChild(KaleaConsumosChartComponent, { static: false }) kaleConsumos: any;
  @ViewChild('comentariosModal') comentariosModal!: ElementRef;
  @ViewChild('inversionModal') inversionModal!: ElementRef;
  @ViewChild('mejorasModal') mejorasModal!: ElementRef;
  @ViewChild('seguimientosModal') seguimientosModal!: ElementRef;
  @ViewChild('informesModal') informesModal!: ElementRef;

  constructor(
    private pdfService: PdfMakeService,
    private pdfService2: PdfMakeService2,
    private pdfSeguimientoService: PdfInformeSeguimientoService,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private usuarioService: UsuariosService,
    private consumosService: ConsumosService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private http: HttpClient
  ) { }

  ngAfterViewInit() {
    console.log('Recuperando elemento: ', this.kaleConsumos);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      console.log(params['id']);
      this.usuarioId = params['id'];

      this.apollo.watchQuery(
        { query: Profile,
          variables: {
            usuario: this.usuarioId
          }
        })
        .valueChanges.subscribe((result: any) => {
          console.log(result);
          this.usuario = result.data.profiles[0];
          // this.urlMaps = "https://maps.google.com/maps?q=loc:43.3226847973798+-1.98588314110445&z=14&ie=UTF8&iwloc=&output=embed";
          console.log(this.urlMaps);
          this.comentarios = this.usuario.comentarios;
          let auxComercializadoras = [...this.usuario.comercializadoras];
          this.comercializadoras = this.usuario.comercializadoras;
          this.tablaComercializadora = [];

          //Ordenar comercializadoras por fechas
          auxComercializadoras.sort(
            (a: Comercializadora, b: Comercializadora) => {
              // console.log(a.date);
              // console.log(b.date);
              // console.log(typeof(a.date));
              // console.log(typeof(b.date));
              let aDate: Date = new Date(a.date);
              let bDate: Date = new Date(b.date);
              return aDate.getTime() - bDate.getTime();
            }
          );
          this.comercializadoras = auxComercializadoras;
          this.comercializadoras.forEach((element, index, array) => {
            this.tablaComercializadora.push({
              desde: element.date,
              hasta: array[index+1] ? array[index+1].date : undefined,
              comercializadora: element.comercializadora,
              esAgrupada: element.esAgrupada
            })
          });
          this.inversiones = this.usuario.inversiones;
          this.mejoras = this.usuario.mejoras;

          // Actualizo los valores de las tres partes del formulario de editar
          this.updateForm.patchValue({
            identifier: this.usuario.identifier,
            usuario: this.usuario.usuario,
            actividad: this.usuario.actividad,
            superficie: this.usuario.superficie,
            zona: this.usuario.zona,
            cuentaUsuario: this.usuario.cuentaUsuario,
            descripcion: this.usuario.descripcion,
          });

          this.updateFormLocalizacion.patchValue({
            identifier: this.usuario.identifier,
            longitud: this.usuario.localizacion ? this.usuario.localizacion.coordinates[0] : null,
            latitud: this.usuario.localizacion ? this.usuario.localizacion.coordinates[1] : null
          });

          this.updateFormBooleanos.patchValue({
            identifier: this.usuario.identifier,
            activo: this.usuario.abierto,
            agrupada: this.usuario.esAgrupada,
          });

          // Pido los datos de calidad del dato
          // this.apollo.watchQuery(
          //   { query: CalidadDato,
          //     variables: {
          //       oidProfile: this.usuario['identifier'],
          //       initDate: "2020-01-01",
          //       endDate: "2020-12-31"
          //     } })
          //   .valueChanges.subscribe((result: any) => {
          //     console.log(result);
          //     this.calidad2020[0].numDias = result.data.analisis[0].numDias;
          //     this.calidad2020[0].porcentajeCorrecto = result.data.analisis[0].porcentajeCorrecto;
          //     this.calidad2020[0].totalEstimado = result.data.analisis[0].totalEstimado;
          // });
          // this.apollo.watchQuery(
          //   { query: CalidadDato,
          //     variables: {
          //       oidProfile: this.usuario['identifier'],
          //       initDate: "2021-01-01",
          //       endDate: "2021-01-31"
          //     } })
          //   .valueChanges.subscribe((result: any) => {
          //     console.log(result);
          //     this.calidad2021[0].numDias = result.data.analisis[0].numDias;
          //     this.calidad2021[0].porcentajeCorrecto = result.data.analisis[0].porcentajeCorrecto;
          //     this.calidad2021[0].totalEstimado = result.data.analisis[0].totalEstimado;
          //     this.calidad2021[0].totalCorrecto = result.data.analisis[0].totalCorrecto;
          //     this.calidad2021[0].totalFallo = result.data.analisis[0].totalFallo;
          //     this.calidad2021[0].totalNulo = result.data.analisis[0].totalNulo;
          //     this.calidad2021[0].totalZero = result.data.analisis[0].totalZero;
          // });

          // Pido los informes
          this.usuarioService.getInformesPerfil(this.usuario.identifier)
            .subscribe((result: any) => {
              console.log('informes::::', result.data);
              this.informes = result.data.informe;
              if(this.informes.length != 0) {
                this.ultimoInforme = this.informes[this.informes.length -1 ];
                console.log('Uiltimo Informe: ', this.ultimoInforme);
              }
            });

          // Pido los valores de seguimientos
          this.apollo.watchQuery(
            { query: Seguimientos,
              variables: {
                oidProfile: this.usuario.identifier
              }
            })
            .valueChanges.subscribe((result: any) => {
              console.log('seguimientos/visitas::::', result.data.seguimiento);
              this.seguimientos = result.data.seguimiento;
              if(this.seguimientos.length != 0) {
                this.ultimoSeguimiento = this.seguimientos[this.seguimientos.length -1 ];
                console.log('Uiltimo Seguimiento: ', this.ultimoSeguimiento.seguimiento.fechaVisita);
              }
            });

          // Pido los valores del contrato
          this.apollo.watchQuery(
            { query: Cups,
              variables: {
                oidProfile: this.usuario.identifier
              }
            })
            .valueChanges.subscribe((result: any) => {
              this.cups = result.data.suministro[0] ? result.data.suministro[0].cups : '-';
              this.direccion = result.data.suministro[0] ? result.data.suministro[0].direccion : 'N/D';
            });
            this.apollo.watchQuery(
              { query: Contrato,
                variables: {
                  oidCuenta: this.usuario.identifier
                }
              })
              .valueChanges.subscribe((result: any) => {
                this.tarifa = result.data.contrato[0] ? result.data.contrato[0].tarifa : 'N/A';
                this.potencia = result.data.contrato[0] ? result.data.contrato[0].potenciaP1 : '-';
              });

              this.apollo.watchQuery(
                { query: Consumo,
                  variables: {
                    idPerfilIn: [this.usuario.idPerfil],
                    firstYearInitDate: "2018-12-01",
                    firstYearEndDate: "2019-11-30",
                    secondYearInitDate: "2019-12-01",
                    secondYearEndDate:"2020-11-30",
                    extraYearInitDate: "2020-12-01",
                    extraYearEndDate: "2021-11-30"
                  },
                  errorPolicy: 'all'
                })
                .valueChanges.subscribe((result: any) => {
                  console.log(result.data);
                  if(result.data.consumo2019!=null || result.data.consumo2019 != undefined) {
                    this.consumo2019 = result.data.consumo2019[0] ? result.data.consumo2019[0].consumoWh / 1000 : 0;
                    console.log("Consumo2019:: ", this.consumo2019, "Tarifa:: ", this.tarifa);
                  } else {
                    this.consumo2019 = 0;
                  }
                  if(result.data.consumo2020 != null || result.data.consumo2020 != undefined) {
                    this.consumo2020 = result.data.consumo2020[0] ? result.data.consumo2020[0].consumoWh / 1000 : 0;
                  } else {
                    this.consumo2020 = 0;
                  }
                  if(result.data.consumo2021 != null || result.data.consumo2021 != undefined) {
                    this.consumo2021 = result.data.consumo2021[0] ? result.data.consumo2021[0].consumoWh / 1000 : 0;
                  }
                  if(this.consumo2019 != 0) {
                    if (this.tarifa == '2.0A'){
                      this.precioAgrupada = this.consumo2019 * 0.1144;
                      this.precioNoAgrupada = this.consumo2019 * 0.15;//Precio sin agrupacion inventado
                    }else if (this.tarifa == '2.0DHA'){
                      this.precioAgrupada = this.consumo2019 * 0.1342;
                      this.precioNoAgrupada = this.consumo2019 * 0.17;//Precio sin agrupacion inventado
                    }else if (this.tarifa == '2.1A'){
                      this.precioAgrupada = this.consumo2019 * 0.1271;
                      this.precioNoAgrupada = this.consumo2019 * 0.16;//Precio sin agrupacion inventado
                    }else if (this.tarifa == '2.1DHA'){
                      this.precioAgrupada = this.consumo2019 * 0.1481;
                      this.precioNoAgrupada = this.consumo2019 * 0.19;//Precio sin agrupacion inventado
                    }else if (this.tarifa == '3.0A'){
                      this.precioAgrupada = this.consumo2019 * 0.0949;
                      this.precioNoAgrupada = this.consumo2019 * 0.15; //Precio sin agrupacion inventado
                    }
                    this.ahorro_agrupada = this.precioNoAgrupada - this.precioAgrupada;
                    console.log("Ahorro agrupada cálculo: ",this.ahorro_agrupada)
                    this.ahorro_string = ((Math.round(this.ahorro_agrupada * 100) / 100).toFixed(2)).replace('.',',');
                    //this.ahorro_string = this.ahorro_string.replace('.',',')
                    console.log('Ahorro agrupada: ', this.ahorro_string);
                  }
                // this.consumos.push(
                //   {
                //     idPerfil: this.usuario.id_perfil,
                //     year: new Date('2020-01-01'),
                //     consumoWh: result.data.consumo2020[0].consumoWh,
                //   }
                // );
              });

          // Pido los valores de consumo
          this.consumosService.getToken('fomentoss', 'fomentoss')
            .subscribe(async (token: any) => {
              // Compruebo si su codigo empieza por D para que busque en Datadis
              console.log(this.cups, this.usuario);
              this.consumosService.getDataConsumos(this.usuario.codigo,this.usuarioId, this.cups, token.token)
              .subscribe((result: any) => {
                console.log('eeee', result);
                this.data = result.items;
              });
              
              //const cups = this.usuario.tipoCuenta === 'Efergy' ? 'source_efergy' : this.cups;

              
          });
          // Pido los valores mensuales
          // this.consumosService.getDataMensual(this.usuarioId)
          //   .subscribe((result: any) => {
          //     console.log('mensual', result.me);
          //     this.dataMes = result.me;
          // });
          // Pido los valores de potencia máxima
          const cups = this.usuario.tipoCuenta === 'Efergy' ? 'source_efergy' : this.cups;
          this.consumosService.getDataPotencias(this.usuarioId, cups)
            .subscribe((result: any) => {
              console.log(result);
              this.dataPotencia = result.items;
          });

          this.http.get('assets/date_ini_contrato.json').subscribe((data: any) => {
            // data es el array de objetos JSON
            let usuarioJson = (data as Array<any>).find(item => item.id === this.usuario.idPerfil);
            console.log("Usuario JSON: ", usuarioJson);  // Imprime el objeto usuarioJson
            
            if (usuarioJson) {
              this.fechaIniContrato = usuarioJson.fecha_ini_contrato;
              
              // Crea un objeto Date a partir de fechaIniContrato
              let fechaIniContratoMoment = moment(this.fechaIniContrato, 'DD/MM/YYYY');
              let fechaFin = fechaIniContratoMoment.add(2, 'years').toDate();

              let fechaIniStr = fechaFin.toLocaleDateString('es-ES');
              // Almacena la nueva fecha en fechaFinContrato
              this.fechaFinContrato = fechaIniStr;
            }
          });
          
        });
    });

  }


  update(){
    console.log(this.updateForm.value);
    this.formEnviado = true;
    this.usuarioService.cambiarPerfil(this.updateForm.value);
  }

  updateBooleanos(){
    console.log(this.updateFormBooleanos.value);
    this.usuarioService.cambiarBooleanos(this.updateFormBooleanos.value);
  }

  updateLocalizacion() {
    console.log(this.updateFormLocalizacion.value);
    this.usuarioService.cambiarLocalizacion(this.updateFormLocalizacion.value);
  }

  addInversion(inversion: any) {
    jQuery(this.inversionModal.nativeElement).modal('hide');
    this.usuarioService.addInversion(this.usuario.identifier, inversion.form.value)
      .subscribe( (inv: any) => {
        console.log(inv);
        this.inversiones = inv.data.addInversion.inversiones;
        Swal.fire({
          title: 'Inversión',
          text: 'La inversion ha sido añadido correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      },(error: any) => {
        Swal.fire({
          title: 'Error inversion',
          text: error,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
  }

  deleteInversion(inversion: any) {
    console.log(inversion);
    Swal.fire({
      title: '¿Está seguro de borrar la inversión?',
      text: 'la inversion será borrada.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar'
    })
    .then( ok => {
      if (ok.value) {

        this.usuarioService.deleteInversion(this.usuario.identifier, inversion)
          .subscribe( (com: any) => {
            console.log(com.data);
            this.inversiones = com.data.deleteInversion.inversiones;
            Swal.fire({
              title: 'Inversión',
              text: 'la inversión ha sido borrado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          },(error: any) => {
            Swal.fire({
              title: 'Error inversion',
              text: error,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          });

      }
    });
  }

  openInversionModal(inversion: any) {
    const modalRef = this.modalService.open(ComentarioComponent);
    modalRef.componentInstance.comentario = inversion;
    modalRef.result
      .then((inversionNew) => {
        console.log('cambio:', inversion, inversionNew); 
        this.usuarioService.editInversion(this.usuario.identifier, inversion, inversionNew)
        .subscribe( (com: any) => {
          this.inversiones = com.data.replaceInversion.inversiones;
          Swal.fire({
            title: 'Inversión',
            text: 'La inversión ha sido editada correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          })
        },(error: any) => {
          Swal.fire({
            title: 'Error inversión',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK'
          })
      });
    });
  }

  addComentario(comentario: any) {
    jQuery(this.comentariosModal.nativeElement).modal('hide');
    this.usuarioService.addComentario(this.usuario.identifier, comentario.form.value)
      .subscribe( (com: any) => {
        this.comentarios = com.data.addComentario.comentarios;
        Swal.fire({
          title: 'Comentario',
          text: 'El comentario ha sido añadido correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      },(error: any) => {
        Swal.fire({
          title: 'Error Comenantario',
          text: error,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
  }

  deleteComentario(comentario: any) {
    console.log(comentario);
    Swal.fire({
      title: '¿Está seguro de borrar el comentario?',
      text: 'El comentario será borrado.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar'
    })
    .then( ok => {
      if (ok.value) {

        this.usuarioService.deleteComentario(this.usuario.identifier, comentario)
          .subscribe( (com: any) => {
            this.comentarios = com.data.deleteComentario.comentarios;
            Swal.fire({
              title: 'Comentario',
              text: 'El comentario ha sido borrado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          },(error: any) => {
            Swal.fire({
              title: 'Error Comentario',
              text: error,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          });

      }
    });
  }

  openComentarioModal(comentario: any) {
    const modalRef = this.modalService.open(ComentarioComponent);
    modalRef.componentInstance.comentario = comentario;
    modalRef.result
      .then((comentarioEditado) => {
        console.log('resultado:', comentarioEditado); 
        this.usuarioService.editComentario(this.usuario.identifier, comentario, comentarioEditado)
        .subscribe( (com: any) => {
          this.comentarios = com.data.replaceComentario.comentarios;
          Swal.fire({
            title: 'Comentario',
            text: 'El comentario ha sido editado correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          })
        },(error: any) => {
          Swal.fire({
            title: 'Error Comenantario',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK'
          })
      });
    });
  }

  addMejora(mejora: any) {
    console.log(mejora);
    jQuery(this.mejorasModal.nativeElement).modal('hide');
    this.usuarioService.addMejora(this.usuario.identifier, mejora.form.value)
      .subscribe( (mej: any) => {
        this.mejoras = mej.data.addMejoraPropuesta.mejoras;
        Swal.fire({
          title: 'Mejora',
          text: 'La mejora ha sido añadido correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      },(error: any) => {
        Swal.fire({
          title: 'Error Mejora',
          text: error,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
  }

  deleteMejora(mejora: any) {
    console.log(mejora);
    Swal.fire({
      title: '¿Está seguro de borrar la mejora?',
      text: 'La mejora será borrada.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar'
    })
    .then( ok => {
      if (ok.value) {
        this.usuarioService.deleteMejora(this.usuario.identifier, mejora)
          .subscribe( (mej: any) => {
            this.mejoras = mej.data.deleteMejoraPropuesta.mejoras;
            Swal.fire({
              title: 'Mejora',
              text: 'La mejora ha sido borrada correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          },(error: any) => {
            Swal.fire({
              title: 'Error Mejora',
              text: error,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          });
      }
    });
  }

  openMejoraModal(mejora: any) {
    const modalRef = this.modalService.open(MejoraComponent);
    modalRef.componentInstance.mejora = mejora;
    modalRef.result
      .then((mejoraEditada) => {
        console.log('resultado:', mejoraEditada); 
        this.usuarioService.editMejora(this.usuario.identifier, mejora, mejoraEditada)
        .subscribe( (mej: any) => {
          this.comentarios = mej.data.replaceMejoraPropuesta.mejoras;
          Swal.fire({
            title: 'Mejora',
            text: 'La mejora ha sido editada correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          })
        },(error: any) => {
          Swal.fire({
            title: 'Error Mejora',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK'
          })
      });
    });
  }

  addInforme(informe: any) {
    jQuery(this.seguimientosModal.nativeElement).modal('hide');
    console.log(informe.form.value)
    this.usuarioService.crearInforme(this.usuario.identifier, informe.form.value.titulo, new Date(informe.form.value.date))
      .subscribe( (inf: any) => {
        console.log(inf);
        this.informes = inf.data.createInforme.informe;
        window.location.reload();
        Swal.fire({
          title: 'Informe',
          text: 'El informe ha sido añadido correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      },(error: any) => {
        Swal.fire({
          title: 'Error Informe',
          text: error,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
  }

  public uploadVersionInforme(informe: any) {
    const modalRef = this.modalService.open(UploadInformeComponent);
    modalRef.componentInstance.informe = informe
    modalRef.result
      .then((peticionCarga) => {
        console.log('resultado:', peticionCarga);
        if(peticionCarga.informe_id === '')
        {
          return;
        }
        if(peticionCarga.file === undefined) {
          Swal.fire({
            title: 'Error en subida de version',
            text: 'No se ha seleccionado archivo para guardar',
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        } else {
          this.usuarioService.uploadNewVersion(peticionCarga.informe_id,peticionCarga.file)
          .subscribe((result: any) => {
            window.location.reload();
          },(error: any)=> {
            Swal.fire({
              title:'Error en subida de versión',
              text: 'Ocurrió un error durante la subida: '+error,
              icon: 'error',
              confirmButtonText: 'Ok'
            });
          });
        }
    });
  }

  public downloadVersionInforme(informe: Informe) {
    if(informe.versiones.length == 0) {
      Swal.fire({
        title:'Informe',
        text: 'No hay versiones para este informe',
        icon: 'info'
      });
    } else {
      let max_numero_versiones: number = 0;
      informe.versiones.map((v) => {
        if(v.version >= max_numero_versiones) {
          max_numero_versiones = v.version;
        }
      });

      const nombre_archivo = informe.titulo+'_'+max_numero_versiones;

      this.usuarioService.downloadLastVersion(
        informe.oid,
        nombre_archivo
      );
    }
  }

  addComercializadora(){
    const modalRef = this.modalService.open(ComercializadoraComponent);
    modalRef.result
      .then((comercializadora) => {
        console.log('resultado:', comercializadora); 
        this.usuarioService.addComercializadora(this.usuario.identifier, comercializadora)
        .subscribe( (com: any) => {
          console.log(com);
        });
      });
  }

  editComercializadora(comercializadora: any) {
    console.log(comercializadora);
    const modalRef = this.modalService.open(ComercializadoraComponent);
    console.log(modalRef.componentInstance);
    modalRef.componentInstance.comercializadora = comercializadora;
    modalRef.result
      .then((comercializadoraEditada) => {
        console.log('resultado:', comercializadoraEditada); 
        this.usuarioService.editComercializadora(this.usuario.identifier, comercializadora, comercializadoraEditada)
        .subscribe( (com: any) => {
          console.log(com);
          this.comercializadoras = com.data.replaceComercializadora.comercializadoras;
          window.location.reload();
          Swal.fire({
            title: 'Comentario',
            text: 'El comentario ha sido editado correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          })
        },(error: any) => {
          Swal.fire({
            title: 'Error Comenantario',
            text: error,
            icon: 'error',
            confirmButtonText: 'OK'
          })
      });
    });
  }

  deleteComercializadora(comercializadora: any) {
    console.log(comercializadora);
    Swal.fire({
      title: '¿Está seguro de borrar la comercializadora?',
      text: 'La comercializadora será borrada.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar'
    })
    .then( ok => {
      if (ok.value) {
        this.usuarioService.deleteComercializadora(this.usuario.identifier, comercializadora)
          .subscribe( (com: any) => {
            console.log(com);
            // this.comercializadoras = com.data.replaceComercializadora.comercializadoras;
            window.location.reload();
            Swal.fire({
              title: 'Comercializadora',
              text: 'La Comercializadora ha sido borrado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          },(error: any) => {
            Swal.fire({
              title: 'Error Comercializadora',
              text: error,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          });

      }
    });
  }

  openInformeModal(informe: any) {
    const modalRef = this.modalService.open(ComentarioComponent);
    modalRef.componentInstance.comentario = informe;
    modalRef.result
      .then((informeEditado) => {
        console.log(informe['titulo'], informeEditado);
        const nuevoInforme: Informe = {
          oid: informe.oid,
          oidProfile: informe.oidProfile,
          fechaInforme: informeEditado.date,
          titulo: informeEditado.comentario,
          versiones: informe.versiones
        }
        console.log('resultado:', informe);
        this.usuarioService.updateInforme(informe.oid, nuevoInforme)
          .subscribe( (inf: any) => {
            console.log(inf.data.updateInforme.informe);
            window.location.reload();
            Swal.fire({
              title: 'Informe',
              text: 'El informe ha sido modificado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          },(error: any) => {
            Swal.fire({
              title: 'Error Informe',
              text: error,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          });
      });
  }

  addSeguimiento(seguimiento: any) {
    jQuery(this.seguimientosModal.nativeElement).modal('hide');
    this.usuarioService.addSeguimiento(this.usuario.identifier, seguimiento.form.value)
      .subscribe( (seg: any) => {
        console.log(seg);
        this.seguimientos = seg.data.createSeguimiento.seguimientos;
        // window.location.reload();
        Swal.fire({
          title: 'Seguimiento',
          text: 'El seguimiento ha sido añadido correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      },(error: any) => {
        Swal.fire({
          title: 'Error Seguimiento',
          text: error,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
  }

  editSeguimiento(seguimiento: any) {
    jQuery(this.seguimientosModal.nativeElement).modal('hide');
    this.usuarioService.editSeguimiento(this.usuario.identifier, seguimiento)
      .subscribe( (seg: any) => {
        console.log(seg);
        this.seguimientos = seg.data.updateSeguimiento.seguimientos;
        // window.location.reload();
        Swal.fire({
          title: 'Seguimiento',
          text: 'El seguimiento ha sido añadido correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
      },(error: any) => {
        Swal.fire({
          title: 'Error Seguimiento',
          text: error,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      });
  }

  deleteSeguimiento(seguimiento: any) {
    console.log(seguimiento);
    Swal.fire({
      title: '¿Está seguro de borrar la visita?',
      text: 'la visita será borrada.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Eliminar'
    })
    .then( ok => {
      console.log(ok);
      if (ok.value) {
        this.usuarioService.deleteSeguimiento(seguimiento.seguimiento.id)
          .subscribe( (seg: any) => {
            console.log(seg);
            window.location.reload();
            //this.seguimientos = seg.data.deleteSeguimiento.comentarios;
            Swal.fire({
              title: 'Seguimiento',
              text: 'El seguimiento ha sido borrado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          },(error: any) => {
            Swal.fire({
              title: 'Error Seguimiento',
              text: error,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          });

      }
    });
  }

  generarInformeSeguimiento( fecha: any) {
    this.pdfService.generatePdf(this.usuario, this.kaleConsumos ? this.kaleConsumos.getImageBase64() : null, this.consumo2019, this.consumo2021, this.potencia, this.tarifa, fecha);
  }
  
  generarInformeSeguimientoTipo2(fecha: any){
    this.pdfService2.generatePdf(this.usuario, this.potencia, this.tarifa, fecha);
  }

  generarMockupSeguimiento() {
    console.log('Mockup de seguimiento');
    this.usuarioService.getGraficaConsumosMock("Año 2021","Año 2022",2000.0,1000.0).subscribe(
      (result: any) => {
        const grafica = result['data']['graficaConsumoMock'][0]['grafica'];
        const periodo_1_value = result['data']['graficaConsumoMock'][0]['primerPeriodo']['value'];
        const periodo_2_value = result['data']['graficaConsumoMock'][0]['segundoPeriodo']['value'];
        this.pdfSeguimientoService.generateMock(this.usuario,this.potencia,this.tarifa,grafica,periodo_1_value,periodo_2_value,undefined,undefined,undefined,undefined,undefined);
      });
  }

  generarInformeSeguimientoV2() {
    console.log('Informe de seguimiento');
    let primer_valor: number | undefined= undefined;
    let segundo_valor: number | undefined = undefined;
    let nombre: string | undefined = undefined;
    let evolucion_potencia: string | undefined = undefined;
    let mejoras_previas: string | undefined = undefined;
    let nuevas_recomendaciones: string | undefined = undefined;
    let comentario_adicional: string | undefined = undefined;

    if(this.newDataInforme.overrideValueUno) {
      primer_valor = this.newDataInforme.valueUno;
    }
    if(this.newDataInforme.overrideValueDos) {
      segundo_valor = this.newDataInforme.valueDos;
    }
    if(this.newDataInforme.overrideNombre) {
      nombre  =this.newDataInforme.nombre;
    }
    if(this.newDataInforme.overridePotencia) {
      evolucion_potencia = this.newDataInforme.evolucion_potencia;
    }
    if(this.newDataInforme.overrideMejorasPrevias) {
      mejoras_previas = this.newDataInforme.mejoras_previas;
    }
    if(this.newDataInforme.overrideNuevasRecomendaciones) {
      nuevas_recomendaciones = this.newDataInforme.nuevas_recomendaciones;
    }
    if(this.newDataInforme.overrideComentarioAdicional) {
      comentario_adicional = this.newDataInforme.comentario_adicional;
    }

    if(this.newDataInforme.overrideConsumos) {
      const grafica = noConsumos;
      const periodo_1_value = 0.0;
      const periodo_2_value = 0.0;
      this.pdfSeguimientoService.generateMock(this.usuario,this.potencia,this.tarifa,grafica,periodo_1_value,periodo_2_value,nombre,evolucion_potencia,mejoras_previas,nuevas_recomendaciones,comentario_adicional);
    } else {

      this.usuarioService.getGraficaConsumosMock("Año 2021","Año 2022",primer_valor,segundo_valor).subscribe(
        (result: any) => {
          const grafica = result['data']['graficaConsumoMock'][0]['grafica'];
          const periodo_1_value = result['data']['graficaConsumoMock'][0]['primerPeriodo']['value'];
          const periodo_2_value = result['data']['graficaConsumoMock'][0]['segundoPeriodo']['value'];
          this.pdfSeguimientoService.generateMock(this.usuario,this.potencia,this.tarifa,grafica,periodo_1_value,periodo_2_value,nombre,evolucion_potencia,mejoras_previas,nuevas_recomendaciones,comentario_adicional);
        });
  
    }
  }

  


}
