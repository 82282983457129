import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroIconos',
  pure: false
})
export class FiltroIconosPipe implements PipeTransform {

  transform(value: string): string {

    let urlIconoImg: string = '../../assets/icon/';
    console.log('TRANSFORM ICONOS: -> '+value);
    
    switch(value) {
        case 'Iberdrola':
            urlIconoImg += 'iberdrola.png';
            break;
        case 'Datadis':
            urlIconoImg += 'datadis.png';
            break;
        case 'SinSeguimiento':
            urlIconoImg += 'sinseguimiento.png';
            break;
        case 'Sinseguimiento':
            urlIconoImg += 'sinseguimiento.png';
            break;
        default:
            urlIconoImg += 'iberdrola.png';
            break;
    }

    return urlIconoImg;

  }

}
