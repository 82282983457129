<div class="modal-header">
    <h4 class="modal-title">Editar</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #comercializadoraEditForm="ngForm" (ngSubmit)="editComercializadora(comercializadoraEditForm.form.value)">
        <div class="form-group">
            <label for="recipient-name" class="control-label">Fecha:</label>
            <input type="text" name="desde" [(ngModel)]="newComercializadora.desde" class="form-control" id="recipient-name1">
        </div>
        <div class="form-group">
            <label for="message-text" class="control-label">Comercializadora:</label>
            <textarea class="form-control" name="comercializadora" [(ngModel)]="newComercializadora.comercializadora" id="message-text1"></textarea>
        </div>
        <div class="form-group">

            <label class="custom-control custom-checkbox">
                <input type="checkbox" name="esAgrupada" [(ngModel)]="newComercializadora.esAgrupada" class="custom-control-input">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Agrupada</span>
            </label>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
    <button type="button" class="btn btn-success" (click)="activeModal.close(newComercializadora)">Guardar</button>
</div>
