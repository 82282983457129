import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RespuestaGetMyData } from '../interfaces/interfaces';

const URL = environment.url;
const urlDjagoKalea = environment.urlDjagoKalea;

@Injectable({
  providedIn: 'root'
})
export class ConsumosService {

  token: string = '';
  tokenKalea = '970a41dbe17125eb13ce19816dfebd3e23204a87';

  constructor(
    private http: HttpClient
  ) { }

  getToken(username: string, password: string) {
    const body = {
      username,
      password
    };
    return this.http.post(URL + '/api-token-auth/', body)
  }

  getDataConsumos(codigo:string, user: string, contrato: string, token: string) {
    console.log(user);
    const headers = {headers: {
      'Authorization': 'JWT ' + token,
      'Content-Type': 'application/json'
    }};
    const granularity = 'Days';
    const toDate = Date.now();
    const fromDate = toDate - (1000 * 60 * 60 * 24 * 30 * 5);
    if (codigo.startsWith('D')) { //Obtener consumos Datadis
      headers.headers.Authorization='Token '+this.tokenKalea;
      return this.http.get<RespuestaGetMyData>(urlDjagoKalea + '/api/get_my_data.json?fromDate=' + fromDate/1000 + '&endDate=' + toDate/1000 + '&granularity='+ granularity + '&contrato_id=' + contrato + '&var_type=Consumo&as_user=' + user, headers);
    }
    return this.http.get<RespuestaGetMyData>(URL + '/datastream/get_my_data.json?fromDate=' + fromDate/1000 + '&endDate=' + toDate/1000 + '&granularity=Months' + '&contrato_id=' + contrato + '&as_user=' + user, headers);
  }

  getDataPotencias(user: string, contrato: string) {
    const headers = {headers: {
      // 'Authorization': 'JWT ' + localStorage.getItem('tokenKE'),
      'Authorization': 'Token ' + this.tokenKalea,
      'Content-Type': 'application/json'
    }};
    const toDate = Date.now();
    const fromDate = toDate - (1000 * 60 * 60 * 24 * 30 * 5);
    return this.http.get<RespuestaGetMyData>(urlDjagoKalea + '/api/get_my_data.json?fromDate=' + fromDate/1000 + '&endDate=' + toDate/1000 + '&granularity=Months' + '&contrato_id=' + contrato + '&as_user=' + user, headers);
  }

  getDataMensual(user: string) {
    const headers = {headers: {
      'Authorization': 'JWT ' + localStorage.getItem('tokenKE'),
      'Content-Type': 'application/json'
    }};

    return this.http.get(urlDjagoKalea + '/appapi/user_monthly_data.json?as_user=' + user, headers);
  }

}
