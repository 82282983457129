

<div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body" *ngIf="data">
          <h4 class="card-subtitle">Listado de {{ data.length }} visitas en la base de datos.</h4>
          
          <div class="row justify-content-center">
            <div>
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="asesoriasOn" (change)="toggleAsesorias($event)" id="filledInCheckbox1">
              <label for="filledInCheckbox1">Asesorías</label>
            </div>
            &nbsp;
              <input class="form-check-input filled-in" type="checkbox" [(ngModel)]="seguimientosOn" (change)="toggleSeguimientos($event)" id="filledInCheckbox2">
              <label for="filledInCheckbox2">Seguimientos</label>
            &nbsp;
          </div>

          <div class="row justify-content-center">
            <div>
              <h6><input [(ngModel)]="textoBuscar" placeholder="Buscar..."></h6>
            </div>
            &nbsp;
            <div>
              <h6>
                <select
                  name="estados[0]"
                  [(ngModel)]="estados[0]">
                  <option disabled selected>Selecciona uno</option>
                  <option
                    *ngFor="let estado of estados"
                    [value]="estado">{{estado}}</option>
                </select>
              </h6>
            </div>
          </div>
          
          <div class="table-responsive m-t-40">      

          <table id="example23" 
            *ngIf="data"
            class="display nowrap table table-hover" 
            data-search="true"
            cellspacing="0" 
            width="100%">                 
              <thead>                          
                <tr>
                  <th>Fecha Visita</th>
                  <th>Tipo Visita</th>
                  <th>Estado</th>    
                  <th>Comercio</th>
                  <th>Empresa</th>
                  <th>Comentarios</th>
                </tr>
              </thead>
              <tbody *ngFor="let n of data | filtro:textoBuscar:true | filtroEstado:asesoriasOn:seguimientosOn | sortBy:false:true">
                <tr>
                  <td>{{ n.seguimiento.fechaVisita }}</td>
                  <td >
                    <span *ngIf="n.seguimiento.esAsesoria" class="badge badge-info">Asesoría</span>
                    <span *ngIf="!n.seguimiento.esAsesoria" class="badge badge-warning">Seguimiento</span>
                  </td>
                  <td>
                    {{n.seguimiento.realizado ? 'Realizado' : 'Planificado'}}
                    <!-- <i class="far" [ngClass]="{'fa-check' : n.realizado, 'fa-bell-o': !n.seguimiento.realizado}">
                        
                    </i> -->
                  </td>
                  <td>{{ n.profile.usuario }}</td>
                  <td>
                    <b>{{ n.seguimiento.empresa }}</b>
                  </td>
                  <td>
                      No hay
                  </td>
                </tr>      
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> 
  </div>
  
