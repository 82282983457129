import { Component, OnInit } from '@angular/core';
import { UsuariosService } from 'src/app/services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agrupada',
  templateUrl: './agrupada.component.html',
  styleUrls: []
})
export class AgrupadaComponent implements OnInit {

  data: any;
  textoBuscar = '';
  asesoriasOn = true;
  seguimientosOn = true;
  estados = ['Todos', 'Realizado', 'Planificado'];

  constructor(
    private usuariosServices: UsuariosService
  ) { }

  ngOnInit(): void {
    this.usuariosServices.cargarPerfiles()
      .subscribe((result: any) => {
        console.log(result);
        // this.data = result.data.profiles.filter( (i:any) => i.esAgrupada && i.codigo.startsWith('DK'));
        this.data = result.data.profiles.filter( (i:any) => i.esAgrupada);
        console.log(this.data);

      });
  }

  toggleAsesorias(e: any){
    this.asesoriasOn = e.target.checked;
  }


}
