import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroEstado',
  pure: false
})
export class FiltroEstadoPipe implements PipeTransform {

  transform(array: any[], esAsesoria: boolean, esSeguimiento: boolean, columna: string = 'esAsesoria'): any[] {

    if (!array) {
      return array;
    }

    if (!esAsesoria) {
      return array.filter(
        item => {
          console.log('esta no es Asesorias', item.seguimiento[columna]);
          return !item.seguimiento[columna]
        });
    } else if (!esSeguimiento) {
      return array.filter(
        item => {
          console.log('esta no es Seguimiento', item.seguimiento[columna]);
          return item.seguimiento[columna]
      });
    } 
    else {
      return array;
    }


  }

}
