import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroFechaAgrupada',
  pure: false
})
export class FiltroFechaAgrupada implements PipeTransform {

  transform(array: any[] ): string {

    // if (!array) {
    //   return array;
    // }

    // if (!esAsesoria) {
    //   return array.filter(
    //     item => {
    //       console.log('esta no es Asesorias', item.seguimiento[columna]);
    //       return !item.seguimiento[columna]
    //     });
    // } else if (!esSeguimiento) {
    //   return array.filter(
    //     item => {
    //       console.log('esta no es Seguimiento', item.seguimiento[columna]);
    //       return item.seguimiento[columna]
    //   });
    // } 
    // else {
    //   return array;
    // }

    // console.log('ITEMS RECIBIDOS',array);
    if (!array) {
      return '--';
    }
    let current_comercializadora: any = undefined;

    array.forEach(
        (element: any) => {
            if(element.esAgrupada === true) {
                current_comercializadora = element;
            }
        }
    );

    if(current_comercializadora === undefined) {
        return '--'
    }

    return current_comercializadora.date;


    // return '@'

  }

}
