import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Comercializadora } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-comercializadora',
  templateUrl: './comercializadora.component.html',
  styleUrls: []
})
export class ComercializadoraComponent implements OnInit {


  @Input() comercializadora = {
    desde: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    comercializadora: '',
    esAgrupada: false
  };
  newComercializadora: any;
  
  @Output() cambioValor: EventEmitter<Comercializadora> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    console.log(this.comercializadora);
      this.newComercializadora = {
        desde: this.comercializadora.desde,
        comercializadora: this.comercializadora.comercializadora,
        esAgrupada: this.comercializadora.esAgrupada
      };
  
  }

  editComercializadora(comercializadora: any) {
    this.cambioValor.emit(comercializadora);
  }

}
