import { NgModule } from '@angular/core';
import { FiltroPipe } from './filtro.pipe';
import { FiltroActividadPipe } from './filtro-actividad.pipe';
import { OrdenarFechaPipe } from './ordenar-fecha.pipe';
import { FiltroEstadoPipe } from './filtro-estado.pipe';
import { FiltroAgrupadaPipe } from './filtro-agrupada.pipe';
import { FiltroInactivoPipe } from './filtro-inactivo.pipe';
import { FiltroIconosPipe } from './filtro-iconos.pipe';
import { FiltroFechaAgrupada } from './filtro-fechaagrupada.pipe';
import { FiltroNombreAgrupada } from './filtro-nombreagrupada.pipe';

@NgModule({
  declarations: [FiltroPipe, FiltroActividadPipe, OrdenarFechaPipe, FiltroEstadoPipe,FiltroAgrupadaPipe,FiltroInactivoPipe,FiltroIconosPipe,FiltroFechaAgrupada,FiltroNombreAgrupada],
  exports: [FiltroPipe, FiltroActividadPipe, OrdenarFechaPipe, FiltroEstadoPipe,FiltroAgrupadaPipe,FiltroInactivoPipe,FiltroIconosPipe,FiltroFechaAgrupada,FiltroNombreAgrupada]
})
export class PipesModule { }
