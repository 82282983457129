<div class="flex">
    <div class="flex-item">
      <div style="display: block;">
        <canvas
          class="canvas_for_chart"
          baseChart
          width="400"
          height="400"
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [colors]="barChartColors"
          [legend]="barChartLegend"
          [chartType]="barChartType"
        ></canvas>
      </div>
    </div>
</div>
  
