<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->

<!-- <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/all.css"> -->

<section id="wrapper" class="login-register login-sidebar" style="background-image:url(../../../assets/images/background/bahia_donostia_blue.jpg);">
    <br>
    <img width="850px" src="../assets/images/logos_europeo.png" alt="LogoFondoEuropeo" />
    <div class="login-box card">
        <div class="card-body">
            <form class="form-horizontal form-material text-center" [formGroup]="loginForm" (ngSubmit)="login()" id="loginForm">
                <a href="javascript:void(0)" class="text-center db"><br/><img width="350px" src="../assets/images/smartkalea_logo.png" alt="Home" /></a>
                <div class="form-group m-t-40">
                    <div class="col-xs-12 text-center">
                        <label class="my-0">
                            <i class="fas fa-user"></i>
                            <input formControlName="username" class="myInput" type="text" required="" placeholder="Username">
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12 text-center">
                        <label class="my-0">
                            <i class="fas fa-key"></i>
                            <input formControlName="password" class="myInput" type="password" required="" placeholder="Password">
                        </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary pull-left p-t-0">
                            <input id="checkbox-signup" type="checkbox" class="filled-in chk-col-light-blue">
                            <label for="checkbox-signup"> Recuérdame </label>
                        </div>
                        <a href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Forgot pwd?</a> </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit">Entrar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                        <div class="social">
                            <a href="https://www.facebook.com/mdbootstrap" target="_blank">
                                <i class="fab fa-facebook-f mr-3"></i>
                              </a>
                        
                              <a href="https://twitter.com/MDBootstrap" target="_blank">
                                <i class="fab fa-twitter mr-3"></i>
                              </a>
                        
                              <a href="https://www.youtube.com/watch?v=7MUISDJ5ZZ4" target="_blank">
                                <i class="fab fa-youtube mr-3"></i>
                              </a>
                        </div>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        ¿No tienes cuenta? <a href="/register" class="text-primary m-l-5"><b>Registrarse</b></a>
                    </div>
                </div>
            </form>
            <form class="form-horizontal" id="recoverform" action="index.html">
                <div class="form-group ">
                    <div class="col-xs-12">
                        <h3>Recover Password</h3>
                        <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input class="form-control" type="text" required="" placeholder="Email">
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->