import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro',
  pure: true
})
export class FiltroPipe implements PipeTransform {

  transform(array: any[], texto: string = '', seguimiento = false , columna: string = 'usuario'): any[] {
    // console.log('ARRAY_PROFILE',array);
    if (texto === '') {
      return array;
    }

    if (!array) {
      return array;
    }

    texto = texto.toLocaleLowerCase();

    if((texto == 'todas las zonas') && (columna == 'zona')) {
      return array;
    }

    if((texto == 'todas las conexiones') && (columna == 'tipoCuenta')) {
      return array;
    }

    return array.filter(( item => {
      if (seguimiento) {
        // console.log(item.profile[columna]);
        return item.profile[columna].toLowerCase().includes(texto);
      } else {
      return item[columna].toLowerCase().includes(texto);
      }
    })
      // item => item[columna].toLowerCase().includes(texto)
    );
  }

}
