import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Comentario } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styleUrls: []
})
export class ComentarioComponent implements OnInit {


  @Input() comentario = {
    __typename : 'Comentario',
    date: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    fechaInforme: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    comentario: '',
    titulo: '',
    inversion: ''
  };
  newComentario: any;
  
  @Output() cambioValor: EventEmitter<Comentario> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    console.log(this.comentario);


    if(this.comentario.__typename == 'Comentario') {
      this.newComentario = {
        date: this.comentario.date,
        comentario: this.comentario.comentario
      };
    } else if (this.comentario.__typename == 'Inversion') {
      this.newComentario = {
        date: this.comentario.date,
        comentario: this.comentario.inversion
      };
    } else {
      this.newComentario = {
        date: this.comentario.fechaInforme,
        comentario: this.comentario.titulo
      };
    }
  }

  editComentario(comentario: any) {
    this.cambioValor.emit(comentario);
  }

}
