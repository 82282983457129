<div class="row animated fadeIn fast" *ngIf="cargando">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="alert alert-info text-center">
          <h4 class="alert-heading">Cargando</h4>
          <i class="fa fa-spin fa-refresh fa-2x"></i>
          <p class="mb-0">Por favor espere... Calidad del dato - Últimos 30 días, desde {{ fechaInicioString }} hasta {{ fechaFinString }}</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">

        <h4 class="font-medium m-t-30">Calidad del dato - Últimos 30 días, desde {{ fechaInicioString }} hasta {{ fechaFinString }}</h4>

        <form [formGroup]="filtrosForm" (ngSubmit)="cargarCalidadDatoZonas()">
          <div class="container">
            <div class="row">
              <div class="col">
                <select formControlName="tiempo">
                  <option value="tiempo">Elige una tiempo</option>
                  <option *ngFor="let tiempo of listaTiempos" [ngValue]="tiempo">{{tiempo}}
                  </option>
               </select>
              </div>
              <div class="col">
                <select formControlName="zona">
                  <option value="zona">Elige una zona</option>
                  <option *ngFor="let zona of listaZonas" [ngValue]="zona">{{zona}}
                  </option>
               </select>
              </div>
              <div class="col">
                <select formControlName="alcance">
                  <option value="alcance">Elige un alcance</option>
                  <option *ngFor="let alcance of listaAlcance" [ngValue]="alcance">{{alcance}}
                  </option>
               </select>
              </div>
              <div class="col">
                <button type="submit" class="btn btn-info">Aplicar filtro</button>
              </div>
            </div>
          </div>
       </form>

        <h4>Promedio periodo seleccionado: {{ promedio }} % con {{ total }} suministros.</h4>
        <br>
        <div *ngFor="let c of calidad">
          <ng-template #popContent>
            Fallos: <b>{{ c.totalFallo }}</b> días <br>
            Nulos: <b>{{ c.totalNulo }}</b> días <br>
            Ceros: <b>{{ c.totalZero }}</b> días <br>
            Correctos: <b>{{ c.totalCorrecto }}</b> días <br>
            Estimados: <b>{{ c.totalEstimado }} días</b>
          </ng-template>
          <div>
            {{ c.nombrePerfil }} - {{ c.porcentajeCorrecto }} % correcto y {{ c.porcentajeEstimado }} % estimado.
            <span type="button" 
              class="badge badge-primary"
              placement="top"
              [ngbPopover]="popContent" 
              popoverTitle="Detalle datos">
              Ver detalle
          </span>
          </div> 
          <div class="progress">
            <div class="progress-bar bg-success" 
              role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" 
              style="height:6px;"
              [style.width]= "c.porcentajeCorrecto.concat('%')"> 
          </div>
          </div>
          <br>
        </div>

      </div>
    </div>
  </div>
</div>