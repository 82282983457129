<div class="card">
    <div class="card-body">
        <h4 class="card-title">{{ titulo }}</h4>

        <highcharts-chart 
          [Highcharts]="Highcharts"
          [options]="chartOptions"
          style="width: 100%; height: 190px; display: block;"
        ></highcharts-chart>
    </div>
</div>
