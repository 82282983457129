<div class="modal-header">
    <h4 class="modal-title">Editar</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #comentarioEditForm="ngForm" (ngSubmit)="editComentario(comentarioEditForm.form.value)">
        <div class="form-group">
            <label for="recipient-name" class="control-label">Fecha:</label>
            <input type="text" name="date" [(ngModel)]="newComentario.date" class="form-control" id="recipient-name1">
        </div>
        <div class="form-group">
            <label for="message-text" class="control-label">Título:</label>
            <textarea class="form-control" name="comentario" [(ngModel)]="newComentario.comentario" id="message-text1"></textarea>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
    <button type="button" class="btn btn-success" (click)="activeModal.close(newComentario)">Guardar</button>
</div>
